import Cookie from 'js-cookie'
import {
  COOKIE_ACCESS_TOKEN_KEY,
  COOKIE_REFRESH_TOKEN_KEY,
  COOKIE_USER_KEY,
  JWT_REGEX,
} from '../constants'

export const clearCookies = () => {
  Cookie.remove(COOKIE_ACCESS_TOKEN_KEY)
  Cookie.remove(COOKIE_REFRESH_TOKEN_KEY)
  Cookie.remove(COOKIE_REFRESH_TOKEN_KEY)
}

export const getAccessToken = () => {
  const jwt = Cookie.get(COOKIE_ACCESS_TOKEN_KEY)
  if (!JWT_REGEX.test(jwt)) {
    clearCookies()
    return false
  }

  return jwt
}
// export const getRefreshToken = () => {
//   const jwt = Cookie.get(COOKIE_REFRESH_TOKEN_KEY)
//   if (!JWT_REGEX.test(jwt)) {
//     clearCookies()
//     return false
//   }

//   return jwt
// }
export const getUser = () => Cookie.get(COOKIE_USER_KEY)
