import Grid from "@mui/material/Grid";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { flex__center } from "themes/sx";
import SimpleEmptyState from "ui-component/SimpleEmptyState";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import { Typography } from "@mui/material";
import { useListPagesQuery } from "store/apis/pagesApi";
import PageCard from "./PageCard";

const ListPages = () => {
  const { isLoading, isError, data, isSuccess } = useListPagesQuery();
  // const IS_VALID = data?.length instanceof Array;

  if (isLoading)
    return (
      <Box sx={{ ...flex__center, height: "100vh" }}>
        <CircularProgress />
      </Box>
    );

  if (isError) return <SimpleErrorMessage />;

  if (isSuccess && data.length === 0) return <SimpleEmptyState />;

  return (
    <>
      <Typography variant="h1" color="gray" mb={8}>
        Recently Created Pages
      </Typography>
      <Grid container spacing={2}>
        {isSuccess &&
          data.map((page) => (
            <Grid key={page._id} item xs={12} sm={6} lg={4} xl={3}>
              <PageCard page={page} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default ListPages;
