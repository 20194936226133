import { createSlice } from "@reduxjs/toolkit";
import config from "config";

const initialState = {
  isOpen: [], // for active default menu
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
};

let id;
const customizationSlice = createSlice({
  name: "customization",
  initialState,
  reducers: {
    menuOpen(state, { payload }) {
      id = payload.id;
      state.isOpen = [id];
    },
    setMenu(state, { payload }) {
      state.opened = payload;
    },
  },
});

export const { menuOpen, setMenu } = customizationSlice.actions;

export default customizationSlice.reducer;
