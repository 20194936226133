import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Chip, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import * as React from "react";
import AppModal from "ui-component/AppModal";
import ConfirmActionDialog from "ui-component/ConfirmActionDialog";
import EditServiceForm from "./EditServiceForm";

export default function ServiceCard({
  selectedLang,
  thumbnail,
  title,
  brief,
  content,
  keywords,
  idx,
  handleDeletion = () => {},
  onAfterEdit = () => {},
}) {
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  return (
    <>
      <AppModal open={openEditModal} setOpen={setOpenEditModal}>
        <EditServiceForm
          selectedLang={selectedLang}
          defaultValues={{ thumbnail, title, content, brief, keywords }}
          onAfterSubmit={(values) => {
            setOpenEditModal(false);
            onAfterEdit(values);
          }}
        />
      </AppModal>
      <ConfirmActionDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => handleDeletion(idx)}
      />
      <Card sx={{ maxWidth: "auto", borderRadius: "15px" }}>
        <CardHeader
          sx={{
            textTransform: "capitalize",
          }}
          action={
            <Box>
              <>
                <Tooltip title="Edit post">
                  <IconButton
                    onClick={() => setOpenEditModal(true)}
                    aria-label="edit"
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="delete this post">
                  <IconButton
                    onClick={() => setOpenConfirmDialog(true)}
                    aria-label="delete"
                    size="large"
                    color="error"
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              </>
            </Box>
          }
          title={title}
          //   subheader={dayjs(createdAt).format("MMMM D, YYYY")}
        />
        <CardMedia component="img" height="194" image={thumbnail} alt="" />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {brief}
          </Typography>

          <Box>
            <Typography fontWeight="bold" mt="20px" mb="7px">
              keywords and tags
            </Typography>
            <Grid container spacing="7">
              {typeof keywords === "string" &&
                keywords.split(",").map((cat) => (
                  <Grid key={cat} item>
                    <Chip variant="outlined" key={cat} label={cat} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
