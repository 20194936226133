import blog from "./blog";
import contact from "./contacts";
import website from "./website";
import admin from "./admin";
import { USER_ROLES } from "constants.js";
import dynamicPages from "./dynamicPages";
// ===========================|| MENU ITEMS ||=========================== //

const menuItems = (userRole) => {
  let items = [admin, blog, contact, website, dynamicPages];
  if (userRole === USER_ROLES.EDITOR) {
    items = [blog, contact, website, dynamicPages];
  }

  if (userRole === USER_ROLES.PUBLISHER) {
    items = [blog];
  }

  if (userRole === USER_ROLES.FORMS) {
    items = [contact];
  }

  return {
    // items: [dashboard, pages, utilities, other],
    items,
  };
};

export default menuItems;
