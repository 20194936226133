import React from "react";
import { Outlet } from "react-router-dom";

// project imports

// ===========================|| MINIMAL LAYOUT ||=========================== //

const MinimalLayout = () => (
  <>
    <Outlet />
  </>
);

export default MinimalLayout;
