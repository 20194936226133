import Typography from "@mui/material/Typography";
import config from "config";
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_PER_PAGE,
} from "constants.js";
import React from "react";
import { useLocation } from "react-router";
import {
  useDeleteMessageMutation,
  useFetchContactMessagesQuery,
  useMarkMessageAsReadMutation,
} from "store/apis/contactApi";
import SimpleEmptyState from "ui-component/SimpleEmptyState";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoading from "ui-component/SimpleLoading";
import PaginationTable from "ui-component/tables/PaginationTable";
const ContactMessages = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultPage = searchParams.get("page");
  const defaultRowsPerPage = searchParams.get("perPage");

  const { data, isError, isSuccess, isLoading } = useFetchContactMessagesQuery({
    page: defaultPage ? +defaultPage : DEFAULT_PAGINATION_PAGE,
    perPage: defaultRowsPerPage
      ? +defaultRowsPerPage
      : DEFAULT_PAGINATION_PER_PAGE,
  });

  const [markMessageAsRead] = useMarkMessageAsReadMutation();
  const deleteMessageMutation = useDeleteMessageMutation();
  const columns = [
    // {
    //   id: "density",
    //   label: "Density",
    //   minWidth: 170,
    //   align: "right",
    //   format: (value) => value.toFixed(2),
    // },
    { id: "name", label: "name" },
    { id: "email", label: "email" },
    { id: "phone", label: "phone" },
    { id: "subject", label: "subject" },
    { id: "message", label: "message" },
  ];
  return (
    <div>
      <Typography mb={5} variant="h1" color="gray">
        Contact Us Messages
      </Typography>
      {isLoading && <SimpleLoading />}
      {isError && <SimpleErrorMessage />}
      {isSuccess && data.data.length === 0 && <SimpleEmptyState />}
      {isSuccess && data?.data?.length > 0 && (
        <PaginationTable
          exportDataLinkUrl={`${config.apiUrl}/api/contact/messages/export`}
          rows={data?.data}
          columns={columns}
          total={data?.meta?.total}
          deleteMutation={deleteMessageMutation}
          onRowClick={(id) => {
            markMessageAsRead({ id });
          }}
        />
      )}
    </div>
  );
};

export default ContactMessages;
