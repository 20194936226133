import { HighlightOffOutlined, Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import privateApi from "api/privateApi";
import React from "react";
import { flex__center } from "themes/sx";
import CircularProgressWithLabel from "./CircularProgressWithLable";
import CustomDialog from "ui-component/dialogs/CustomDialog";

const ImageUploader = ({
  onAfterUpload = (imageUrl) => {},
  onAfterDelete = () => {},
  onAfterTextSave = () => {},
  onSave = () => {},
  defaultThumbnail = "",
  defaultTextInputValue = "",
  textInputPlaceholder = "Write your text here",
  title = "",
  shouldPreviewImage = true,
  withEditableText = false,
  isNewItem = false,
}) => {
  const [isUploading, setIsUploading] = React.useState(null);
  const [imageSrc, setImageSrc] = React.useState(defaultThumbnail);
  const [uploadPercentage, setUploadPercentage] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState("");

  const clearData = () => {
    setImageSrc(null);
    setUploadPercentage(0);
    setTextInputValue("");
    setIsUploading(null);
  };
  const handleImageUpload = React.useCallback(
    (e) => {
      const file = e?.target?.files?.[0];
      const formData = new FormData();
      formData.append("image", file);
      setIsUploading(true);
      privateApi
        .post("/admin/image/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(() =>
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );
          },
        })
        .then(({ data }) => {
          if (shouldPreviewImage) {
            setImageSrc(data.url);
          }
          onAfterUpload(data.url);
          setIsUploading(false);
        })
        .catch(() => {
          setIsUploading(undefined);
        })
        .finally(() => setUploadPercentage(0));
    },
    [onAfterUpload, shouldPreviewImage]
  );

  React.useEffect(() => {
    if (defaultThumbnail) {
      setImageSrc(defaultThumbnail);
    }
  }, [defaultThumbnail]);
  return (
    <Tooltip title={title} sx={{ cursor: "pointer" }}>
      <Card
        sx={{ width: "500px" }}
        variant="elevation"
        className="image-uploader"
      >
        <CardContent
          sx={{
            borderRadius: "inherit",
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          {imageSrc && (
            <Tooltip title="remove image">
              <IconButton
                color="error"
                onClick={() => {
                  setImageSrc(null);
                  onAfterDelete();
                }}
                sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
              >
                <HighlightOffOutlined />
              </IconButton>
            </Tooltip>
          )}
          {isUploading && uploadPercentage < 100 && (
            <CircularProgressWithLabel value={uploadPercentage} />
          )}
          {isUploading && uploadPercentage === 100 && <CircularProgress />}
          {isUploading !== true && !imageSrc && (
            <Box sx={{ cursor: "pointer" }}>
              <Button color="primary" sx={{ cursor: "pointer" }}>
                <label style={{ ...flex__center }}>
                  <Upload fontSize="large" />
                  Upload an image
                  <input onChange={handleImageUpload} type="file" hidden />
                </label>
              </Button>
            </Box>
          )}
          {imageSrc && (
            <CardMedia
              sx={{
                borderRadius: "inherit",
                opacity: "0.8",
              }}
              component="img"
              image={imageSrc}
              alt="upload image"
            />
          )}
          {/* {isUploading === undefined && (
            <Typography variant="subtitle1" color="red">
              failed to upload image, please try again!
            </Typography>
          )} */}
        </CardContent>
        {withEditableText && (
          <CardActions>
            <Box
              onClick={() => setOpenDialog(true)}
              width="100%"
              bgcolor="rgba(100,100,100,0.6)"
              color="#FFF"
              p={2}
              borderRadius={2}
              sx={{
                zIndex: 3,
                "&:hover": {
                  bgcolor: "rgba(100,100,100,0.8)",
                  cursor: "pointer",
                },
              }}
            >
              {textInputValue || defaultTextInputValue ? (
                <Typography>
                  {textInputValue || defaultTextInputValue}
                </Typography>
              ) : (
                <Typography color="grey.100">{textInputPlaceholder}</Typography>
              )}
            </Box>

            <CustomDialog
              open={openDialog}
              defaultValue={defaultTextInputValue}
              onDialogAddClick={(value) => {
                onAfterTextSave(value);
                setTextInputValue(value);
                setOpenDialog(false);
              }}
              onClose={() => setOpenDialog(false)}
            />
            {isNewItem && (
              <Box mx={2}>
                <Button
                  onClick={() => {
                    onSave(imageSrc, textInputValue);
                    clearData();
                  }}
                  variant="outlined"
                >
                  Save
                </Button>
              </Box>
            )}
          </CardActions>
        )}
      </Card>
    </Tooltip>
  );
};

export default ImageUploader;
