import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useSnackBar } from "context/SnackbarContext";
import { useFormik } from "formik";
import React from "react";
import {
  useEditWebsiteDataMutation,
  useFetchWebsiteDataQuery,
} from "store/apis/websiteApi";
import FormSection from "ui-component/forms/FormSection";
import ImageUploader from "ui-component/ImageUploader";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoader from "ui-component/SimpleLoading";
import ServiceCard from "views/services/ServiceCard";
import * as Yup from "yup";
import AppModal from "ui-component/AppModal";
import EditServiceForm from "views/services/EditServiceForm";
import { flex__center } from "themes/sx";
const ServicesAndFeaturesEditForm = () => {
  const [editWebsite, { isLoading: isEditingWebsite }] =
    useEditWebsiteDataMutation();
  const {
    data: websiteData,
    isLoading: isGetWebsiteLoading,
    isSuccess: isGetWebsiteSuccess,
    isError: isGetWebsiteError,
  } = useFetchWebsiteDataQuery();
  const { setShow, setMessage } = useSnackBar();

  // states
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [openADdServiceModal, setOpenAddServiceModal] = React.useState(false);
  // const [placeholderFeature, setPlaceholderFeature] = React.useState(null);

  // const clearPlaceholderFeature = () => setPlaceholderFeature(null);

  const onSubmit = React.useCallback(
    (values) => {
      // const lng = selectedLang;
      const data = {
        whyTalentSupplierSection: {
          features: values.features,
        },
        services: values.services,
        postServiceImage: values.postServiceImage,
      };
      editWebsite(data)
        .unwrap()
        .then(() => {
          setMessage({
            text: "website data is updated successfully",
            type: "success",
          });
          setShow(true);
          // wait(1000).then(() => navigate(POST_LIST_PATH));
        })
        .catch(() => {
          setMessage({
            text: "failed to update website data, please try again",
            type: "error",
          });
          setShow(true);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editWebsite, setShow, setMessage, selectedLang]
  );
  const formProps = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  });
  const handleLanguageSelect = (e) => {
    setSelectedLang(e.target.value);
  };

  /* set default values to db values */
  React.useEffect(() => {
    if (isGetWebsiteSuccess) {
      // const lng = selectedLang;
      formProps.setValues({
        features: websiteData?.whyTalentSupplierSection?.features,
        services: websiteData?.services,
        postServiceImage: websiteData?.postServiceImage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetWebsiteSuccess, selectedLang]);

  if (isGetWebsiteLoading) return <SimpleLoader />;
  if (isGetWebsiteError) return <SimpleErrorMessage />;
  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit}
      dir={selectedLang === "en" ? "ltr" : "rtl"}
    >
      <Select
        value={selectedLang}
        onChange={handleLanguageSelect}
        defaultValue="en"
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
      {/* START OF FIELDS */}
      <FormSection title="why talent supplier - features">
        <Stack direction="row" overflow="auto" spacing={2}>
          {formProps.values.features instanceof Array &&
            formProps.values.features.map((feature, idx) => (
              <Box key={feature.img + feature.name}>
                <ImageUploader
                  withEditableText
                  defaultTextInputValue={feature?.name?.[selectedLang]}
                  defaultThumbnail={feature.img}
                  textInputPlaceholder="Write feature name"
                  onAfterTextSave={(value) => {
                    let features = [...formProps.values.features];
                    let selectedFeature = features[idx];
                    const newName = {
                      ...selectedFeature.name,
                      [selectedLang]: value,
                    };
                    const updatedFeature = {
                      ...selectedFeature,
                      name: {
                        ...newName,
                      },
                    };
                    features[idx] = updatedFeature;
                    formProps.setFieldValue("features", features);
                  }}
                  title={`why talent item number ${idx + 1}`}
                  onAfterDelete={() => {
                    let features = [...formProps.values.features];
                    features.splice(idx, 1);
                    formProps.setFieldValue("features", features);
                  }}
                  onAfterUpload={(imageUrl) => {
                    let features = [...formProps.values.features];
                    features[idx].img = imageUrl;
                    formProps.setFieldValue("features", features);
                  }}
                />
              </Box>
            ))}
          <Box>
            <ImageUploader
              withEditableText
              isNewItem
              title={`Add One More`}
              defaultThumbnail={null}
              onSave={(img, text) => {
                const features = [
                  ...formProps.values.features,
                  {
                    name: {
                      [selectedLang]: text,
                    },
                    img,
                  },
                ];

                formProps.setFieldValue("features", features);
                // clearPlaceholderFeature();
              }}
            />
          </Box>
        </Stack>
      </FormSection>

      <FormSection title="Services we offer">
        <AppModal open={openADdServiceModal} setOpen={setOpenAddServiceModal}>
          <EditServiceForm
            selectedLang={selectedLang}
            onAfterSubmit={(values) => {
              const newService = {
                title: {
                  [selectedLang]: values.title,
                },
                brief: {
                  [selectedLang]: values.brief,
                },
                thumbnail: values.thumbnail,
                keywords: {
                  [selectedLang]: values.keywords,
                },
                content: {
                  [selectedLang]: values.content,
                },
              };

              const services = [...formProps.values.services, newService];
              formProps.setFieldValue("services", services);
              setOpenAddServiceModal(false);
            }}
          />
        </AppModal>
        <Box my={3}>
          <Button
            onClick={() => setOpenAddServiceModal(true)}
            my={"10px"}
            color="secondary"
            variant="contained"
          >
            <Typography mr={2}>Add New Service</Typography>
            <AddIcon />
          </Button>
        </Box>
        <Grid container spacing={2}>
          {formProps.values.services.map((service, idx) => (
            <Grid key={service._id} item xs={12} sm={6} lg={4} xl={3}>
              <ServiceCard
                selectedLang={selectedLang}
                idx={idx}
                thumbnail={service?.thumbnail}
                title={service?.title?.[selectedLang]}
                brief={service?.brief?.[selectedLang]}
                keywords={service?.keywords?.[selectedLang]}
                content={service?.content?.[selectedLang]}
                onAfterEdit={(values) => {
                  const updatedService = {
                    title: {
                      ...service.title,
                      [selectedLang]: values.title,
                    },
                    brief: {
                      ...service.brief,

                      [selectedLang]: values.brief,
                    },
                    thumbnail: values.thumbnail,
                    keywords: {
                      ...service.keywords,

                      [selectedLang]: values.keywords,
                    },
                    content: {
                      ...service.content,
                      [selectedLang]: values.content,
                    },
                  };

                  const services = [...formProps.values.services];
                  services[idx] = { ...updatedService };
                  formProps.setFieldValue("services", [...services]);
                }}
                handleDeletion={(idx) => {
                  const newServices = [...formProps.values.services];
                  newServices.splice(idx, 1);
                  formProps.setFieldValue("services", newServices);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </FormSection>

      <FormSection title="Common services image">
        <Box sx={{ ...flex__center }}>
          <ImageUploader
            defaultThumbnail={formProps.values.postServiceImage}
            onAfterUpload={(imageUrl) => {
              formProps.setFieldValue("postServiceImage", imageUrl);
            }}
          />
        </Box>
      </FormSection>

      {/* END OF FIELDS */}

      <Button
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "20px",
        }}
        type="submit"
        variant="contained"
        disabled={isEditingWebsite}
      >
        {isEditingWebsite ? "Loading..." : "Save Changes"}
      </Button>
    </form>
  );
};

export default ServicesAndFeaturesEditForm;

const initialValues = {
  features: [],
  services: [],
  postServiceImage: "",
};

const validationSchema = Yup.object().shape({});
