import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
const CustomTextInput = ({
  name = "",
  label = "",
  type = "text",
  ...formProps
}) => {
  const classes = useStyles();
  return (
    <FormControl
      fullWidth
      error={Boolean(formProps.touched[name] && formProps.errors[name])}
      className={classes.loginInput}
    >
      <InputLabel htmlFor="outlined-adornment-email-login">{label}</InputLabel>
      <OutlinedInput
        // id="outlined-adornment-email-login"
        type={type}
        value={formProps.values[name]}
        name={name}
        onBlur={formProps.handleBlur}
        onChange={formProps.handleChange}
        label={label}
        // inputProps={{
        //   classes: {
        //     notchedOutline: classes.notchedOutline,
        //   },
        // }}
      />
      {formProps.touched[name] && formProps.errors[name] && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {formProps.errors[name]}{" "}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomTextInput;

// style constant
const useStyles = makeStyles((theme) => ({
  loginInput: {
    ...theme.typography.customInput,
  },
}));
