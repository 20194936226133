import axios from "axios";
import config from "config.js";
import { clearCookies, getAccessToken } from "utils/getCookiesHelpers";

const privateApi = axios.create({
  baseURL: config.apiUrl + "/api/",
});

// const refreshToken = (failedRequest) =>
//   publicApi
//     .get("auth/refresh-token")
//     .then(({ data }) => {
//       Cookie.set(COOKIE_ACCESS_TOKEN_KEY, data.accessToken);
//       failedRequest.response.config.headers["Authorization"] =
//         "Bearer " + data.accessToken;
//       return Promise.resolve();
//     })
//     .catch(async () => {
//       //   await logout();
//       privateApi.get("auth/logout");
//       clearCookies();
//       window.location.replace("/login");
//       return Promise.reject();
//     });

// createAuthRefreshInterceptor(privateApi, refreshToken, {
//   pauseInstanceWhileRefreshing: false,
// });

privateApi.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken() ? "Bearer " + getAccessToken() : "";
    config.headers["Authorization"] = accessToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
privateApi.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response?.status === 401) {
      clearCookies();
      window.location.replace("/login");
    }

    return Promise.reject(error);
  }
);

export default privateApi;
