export const websiteSeedData = {
  introSection: {
    mainPic:
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635459346/home/banner-one/main-pic_d0kjtd.png",
    images: [
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456419/home/banner-one/img1_c1smdo.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456419/home/banner-one/img2_qg7oja.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456427/home/banner-one/img3_s1qczs.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456419/home/banner-one/img4_spoyyt.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456419/home/banner-one/img5_uz6kw2.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456420/home/banner-one/img6_pmx8wx.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456421/home/banner-one/img7_mmrnrm.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456421/home/banner-one/img8_ovjymn.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456425/home/banner-one/img9_vxb97j.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456422/home/banner-one/img10_ifokzr.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456422/home/banner-one/img11_p3qjli.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456423/home/banner-one/img12_gq5nmh.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456423/home/banner-one/img13_lnapu3.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456424/home/banner-one/img14_y7zbaz.png",
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635456424/home/banner-one/img15_xpk2id.png",
    ],
    title: {
      en: "Talent Supplier, Putting Talents In The Right Place.",
      ar: "Talent Supplier نعمل على وضع المواهب في مكانها الصحيح.",
    },
    subtitle: {
      en: "Talent Supplier gets your back with expert developers that will solve any technical issue in few clicks. And will minimize any risk factor till it fades.",
      ar: "شركةTalent Supplier تدعمك بعدد من المبرمجين المتميزين، والذين لديهم قدرة على حل أصعب المشاكل التقنية في وقت قياسي. كما يمكنهم تقليل المخاطر التي يمكنها ان تواجهها في شركتك إلى أقل حد.",
    },
  },
  clientsLogos: [
    "https://res.cloudinary.com/talent-supplier/image/upload/v1635456690/home/partners/Bee_q6eoc5.png",
    "https://res.cloudinary.com/talent-supplier/image/upload/v1635456685/home/partners/Q-reduction_guutny.png",
    "https://res.cloudinary.com/talent-supplier/image/upload/v1635456684/home/partners/RFA_sqxobd.png",
    "https://res.cloudinary.com/talent-supplier/image/upload/v1635456682/home/partners/Orchida_cnd6qf.png",
    "https://res.cloudinary.com/talent-supplier/image/upload/v1635456682/home/partners/Maayish_nwbfr0.png",
    "https://res.cloudinary.com/talent-supplier/image/upload/v1635456681/home/partners/Forus_cl6utl.png",
  ],
  servicesSection: {
    title: {
      en: "Services We Offer",
      ar: "خدماتنا",
    },
    subtitle: {
      en: "The services we provide takes the client to a whole new level!",
      ar: "جودة أعمالنا ستحدث طفرة في نتائج شركتك",
    },
  },
  services: [
    {
      title: {
        en: "Offshore Outsourcing",
        ar: "Offshore Outsourcing",
      },
      brief: {
        en: "We outsource number of outstanding developers and support them with site and all needed tools to be able to deliver creative high-quality projects. Our developers not only have the needed experience but also the passion for the field.",
        ar: "تعتبر Talent Supplier واحدة من أكثر ال Software House التي يتم ترشيحها في مصر والسعودية. وذلك لأننا نختار أكفأ المبرمجين الذين لديهم المقدرة الكافية لانتاج مشاريع عالية الجودة. ووضع اضافتهم القيمة بها.",
      },
      content: {
        en: "",
        ar: "",
      },
    },
    {
      title: {
        en: "Software House",
        ar: "Software House",
      },
      brief: {
        en: "We are one from the highest recommended software house in Egypt and Saudi Arabia. As Talent Supplier chooses the highly skilled developers who don’t only deliver high professional projects but also add great value to it",
        ar: "من أهم خدماتنا هي الاوت سورس ، حيث نقوم بتعهيد عدد من أفضل المبرمجين في مصر وندعهم بمقر العمل وكل الادوات الي يتحاجونها، ليتمكنوا من انتاج مشاريع عالية الجودة وفي وقت قياسي. فالمميز عند المبرمجين خاصتنا أنهم لديهم الشغف بالبرجمة بالاضافة إلى الخبرة العالية.",
      },
      content: {
        en: "",
        ar: "خدمة الاستشارات الفنية تساعدك على وضع افكارك في الاتجاة الصحيح. فالخبراء لدينا سيعطوك اجابات مخصصة لعلامتك التجارية وليس اجابات عامة.",
      },
    },
    {
      title: {
        en: "Advertising Solutions",
        ar: "Advertising Solutions",
      },
      brief: {
        en: "We don’t only get your back in web developing, but also in marketing and advertising. Your brand is in professional safe hands.",
        ar: "نحن على أتم الاستعداد لدعمك في التسويق بالاضافة للبرمجة . فلا تقلق على علامتك التجارية، فهي في ايد محترفين",
      },
      content: {
        en: "",
        ar: "",
      },
    },
    {
      title: {
        en: "Technical Consultation",
        ar: "Technical Consultation",
      },
      brief: {
        en: "Technical consultation service is made to help you to put your ideas on the right track. Our expert team will help you to get the best customized answer for your project",
        ar: "تعتبر Talent Supplier واحدة من أكثر ال Software House التي يتم ترشيحها في مصر والسعودية. وذلك لأننا نختار أكفأ المبرمجين الذين لديهم المقدرة الكافية لانتاج مشاريع عالية الجودة. ووضع اضافتهم القيمة بها.",
      },
      content: {
        en: "",
        ar: "",
      },
    },
  ],
  whyTalentSupplierSection: {
    title: {
      en: "Why Talent Supplier !",
      ar: "لماذا تختار Talent supplier !",
    },
    features: [
      {
        name: {
          en: "FAST HIRING",
          ar: "توظيف سريع",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457420/home/why-talent/FAST_HIRING_bmaqad.png",
      },
      {
        name: {
          en: "TIME ZONE",
          ar: "منطقه زمنية",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457423/home/why-talent/TIME_ZONE_fhragc.png",
      },
      {
        name: {
          en: "EFFICIENCY",
          ar: "كفاءة",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457420/home/why-talent/Efficiency_wdjwcd.png",
      },
      {
        name: {
          en: "COST DEDUCTION",
          ar: "خصم التكلفة",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457420/home/why-talent/COST_DEDUCTION_kh91sz.png",
      },
      {
        name: {
          en: "DATA SECURITY",
          ar: "أمن البيانات",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457420/home/why-talent/DATA_SECURITY_gr8gkj.png",
      },
      {
        name: {
          en: "COMMITMENT",
          ar: "التزام",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457420/home/why-talent/COMMITMENT_jlljfd.png",
      },
      {
        name: {
          en: "OUTSTANDING DEVELOPERS",
          ar: "المطورون المتميزون",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457421/home/why-talent/OUTSTANDING_tznfmw.png",
      },
      {
        name: {
          en: "PRODUCTIVE WORK ENVIRONMENT",
          ar: "بيئة عمل منتجه",
        },
        img: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457424/home/why-talent/work_env_p2gtzn.svg",
      },
    ],
  },
  navbar: {
    navbarLinks: [
      {
        name: {
          en: "About Us",
          ar: "من نحن",
        },
        route: "/about-us",
      },
      {
        name: {
          en: "Services",
          ar: "خدماتنا",
        },
        route: "/services-details?id=0",
      },
      {
        name: {
          en: "Why Talent Supplier !",
          ar: "لماذا تختار Talent supplier !",
        },
        route: "/#why_talent_section",
        isUrl: true,
      },
      {
        name: {
          en: "Blogs",
          ar: "مدونات",
        },
        route: "/blog?page=1",
      },
      {
        name: {
          en: "Contact Us",
          ar: "تواصل معنا",
        },
        route: "/contact",
      },
    ],
  },
  socialLinks: [
    {
      logo: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457884/general/facebook_nov1ia.png",
      url: "https://www.facebook.com/Talentsupplier/",
    },
    {
      logo: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457884/general/twitter_ofmeac.png",
      url: "https://twitter.com/TalentSupplier",
    },
    {
      logo: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457884/general/instagram_xjt7ul.png",
      url: "https://www.instagram.com/talent_supplier",
    },
    {
      logo: "https://res.cloudinary.com/talent-supplier/image/upload/v1635457884/general/linkedin_xk7hop.png",
      url: "https://www.linkedin.com/company/talent-supplier/mycompany/",
    },
  ],
  ksaOfficeInfo: {
    address: {
      en: "8125, Prince Sultan St, Al Murjanah Tower, Jeddah",
      ar: "8125 شارع الأمير سلطان ، برج المرجانه ، جدة",
    },
    phone: "(+966) 54-900-0101",
  },
  egyptOfficeInfo: {
    address: {
      en: "155 St, Maadi, Building No.7 Behind Maadi Hotel",
      ar: "155 شارع ، المعادى، بناية رقم 7 ، خلف فندق المعادى",
    },
    phone: "(+20) 115 126 2460",
  },
  mapEmbedUrl:
    "https://www.google.com/maps/d/embed?mid=1t704UU-tWA3w1rKBNrucSt6H3xMMMPae",
  aboutUs: {
    image:
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635458006/about/about_dyd8k9.png",
    content: {
      en: "",
      ar: "",
    },
  },
  FAQs: {
    image:
      "https://res.cloudinary.com/talent-supplier/image/upload/v1635458055/about/faq-1_epqzhh.png",
    title: {
      en: "Frequently Asked Questions",
      ar: "أسئلة شائعة",
    },
    faqList: [
      {
        title: {
          en: "How can offshore outsourcing benefit my business?",
          ar: "كيف يمكن للاوت سورس أن يساعدني في العمل الخاص بي؟",
        },
        description: {
          en: "Outsourcing can open your global talent pool. Countries like Egypt, Philippines, China, India, and others has outstanding talents with low labor cost. So, you can hire more developer with lower cost.",
          ar: "يفتح لك الاوت سورس حقلًا من المواهب من مختلف بلدان العالم. فبلاد مثل مصر والفلبين والصين والهند وغيرهم لديهم مواهب متميزة وبتكلفة منافسة. مما يمكنك من الوصول الى المواهب بشكل لا محدود",
        },
      },
      {
        title: {
          en: "Isn’t different timing can make a communication issue?",
          ar: "هل اختلاف التوقيت من الممكن أن يسبب لنا مشكلة تواصل؟",
        },
        description: {
          en: "Not in all cases, but if you are worried about that, you can head to nearshoring. Which means to hire talents from different countries but in the same time zone",
          ar: "ليس في جميع الحالات، فيمكنك أن تختار مبرمجين من بلاد لها توقيت مقارب من توقيت بلادك.",
        },
      },
      {
        title: {
          en: "How can I reach you if I am business owner?",
          ar: "كيف يمكنني الوصول اليكم للاستفادة من خدماتكم؟",
        },
        description: {
          en: "You can go for Hire Now us and press on business owner and send us a message.",
          ar: "يمكنك الذهاب الى صفحة وظف الاّن والضعط على زر أصحاب الشركات. وارسل لنا رسالة من هذه الصفحة",
        },
      },
      {
        title: {
          en: "If I want to hire developers from your side, what will be the process?",
          ar: "إذا اردت أن اقوم بتعيين عدد من المبرمجين عن طريقكم، ما هي الخطوات المتبعة؟",
        },
        description: {
          en: "As a business owner, you can identify the qualification you need for employees. We make an ad and create a shortlist. After that we as Talent Supplier make HR interviews. Next step is technical interview from your side.",
          ar: "يقوم صاحب الشركة بتحديد المهارات المطلوبة في المبرمج، ومن ثم تقوم Talent Supplier بعمل اعلان توظيف ونعمل قائمة مصغرة من افضل المرشحين ثم تقوم الشركة بعمل مقابلات شحصية عن طريق قسم الموارد البشرية الخاص بالشركه ونختار الأفضل منهم ونعرضهم عليك لتقوم بعمل مقابلة شخصية واختبار لمهاراتهم التقنية.",
        },
      },
      {
        title: {
          en: "Will I choose the developers and make them interviews?",
          ar: "هل سأقوم باختيار المبرمجين الذين سيعملون معي؟",
        },
        description: {
          en: "Yes, for sure, we make a shortlist from developers. And you can make technical interviews as much as you want. Once you approved, we take it from here and make all the needed documents from them.",
          ar: "بالتأكيد، حيث بعد عمل المقابلات الشخصية الخاصة بقسم الموارد البشرية لدينا، نعرض عليك أفضل المرشحين لتقوم بعمل مقابلات شخصية معهم لإختبار قدراتهم المهنية والتقنية.",
        },
      },
    ],
  },
};
