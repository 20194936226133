import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import { flex__center } from "themes/sx";
import AppHtmlEditor from "ui-component/AppHtmlEditor";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import ImageUploader from "ui-component/ImageUploader";
import * as yup from "yup";

const EditServiceForm = ({
  selectedLang = "en",
  onAfterSubmit = () => {},
  defaultValues,
}) => {
  const onSubmit = (values) => {
    onAfterSubmit(values);
  };

  const formProps = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  });

  React.useEffect(() => {
    if (defaultValues) {
      formProps.setFieldValue("title", defaultValues.title);
      formProps.setFieldValue("brief", defaultValues.brief);
      formProps.setFieldValue("thumbnail", defaultValues.thumbnail);
      formProps.setFieldValue("content", defaultValues.content);
      formProps.setFieldValue("keywords", defaultValues.keywords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit}
      dir={selectedLang === "ar" ? "rtl" : "ltr"}
    >
      <FormSection title="service thumbnail">
        <Box sx={{ ...flex__center, mb: "20px" }}>
          <ImageUploader
            defaultThumbnail={formProps.values.thumbnail}
            onAfterUpload={(imageUrl) => {
              formProps.setFieldValue("thumbnail", imageUrl);
            }}
          />
        </Box>
      </FormSection>
      <FormSection title="service details">
        <CustomTextInput {...formProps} name="title" label="service title" />
        <CustomTextInput {...formProps} name="brief" label="service brief" />
        <CustomTextInput
          {...formProps}
          name="keywords"
          label="service keywords"
        />
      </FormSection>
      <FormSection title="service content">
        <AppHtmlEditor
          defaultValue={formProps.values.content}
          onRichTextEditorChange={(content) => {
            formProps.setFieldValue("content", content);
          }}
          label="Service Content"
          displayMode="EDIT"
          placeholder="Write your service content here"
        />
      </FormSection>
      <Button type="submit" variant="outlined">
        Save
      </Button>
    </form>
  );
};

export default EditServiceForm;

const initialValues = {
  thumbnail: "",
  title: "",
  brief: "",
  content: "",
  keywords: "",
};

const validationSchema = yup.object().shape({});
