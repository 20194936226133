// assets
import { IconUsers } from "@tabler/icons";
import { CONTROL_ADMINS_PATH } from "routes/paths";

// constant
const icons = {
  IconUsers,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const admin = {
  id: "admin",
  title: "Admin",
  type: "group",
  children: [
    {
      id: "control-admins",
      title: "Control Admins",
      type: "item",
      url: CONTROL_ADMINS_PATH,
      icon: icons.IconUsers,

      breadcrumbs: false,
    },
  ],
};

export default admin;
