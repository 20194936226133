import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import { flex__center } from "themes/sx";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import ImageUploader from "ui-component/ImageUploader";

const SocialLinkForm = ({
  closeModal,
  defaultValues,
  onAfterSave = () => {},
}) => {
  const onSubmit = (values) => {
    closeModal();
    onAfterSave(values);
  };
  const formProps = useFormik({
    initialValues,
    onSubmit,
  });

  //   console.log(defaultValues);
  React.useEffect(() => {
    if (defaultValues) {
      formProps.setValues({
        url: defaultValues?.url,
        logo: defaultValues?.logo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
  return (
    <form noValidate onSubmit={formProps.handleSubmit}>
      <FormSection title="Social Link">
        <Box sx={{ ...flex__center, mb: "20px" }}>
          <ImageUploader
            title="logo"
            defaultThumbnail={formProps.values.logo}
            onAfterUpload={(imageUrl) => {
              formProps.setFieldValue("logo", imageUrl);
            }}
          />
        </Box>
        <CustomTextInput {...formProps} name="url" label="link url" />
      </FormSection>
      <Button type="submit" variant="outlined">
        Save
      </Button>
    </form>
  );
};

export default SocialLinkForm;

const initialValues = {
  url: "",
  logo: "",
};
