import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import { useSnackBar } from "context/SnackbarContext";
import { useFormik } from "formik";
import React from "react";
import {
  useEditWebsiteDataMutation,
  useFetchWebsiteDataQuery,
} from "store/apis/websiteApi";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoader from "ui-component/SimpleLoading";
import camelToSpaces from "utils/camelToSpaces";
import * as Yup from "yup";
const HomeEditForm = () => {
  const [editWebsite, { isLoading: isEditingWebsite }] =
    useEditWebsiteDataMutation();
  const {
    data: websiteData,
    isLoading: isGetWebsiteLoading,
    isSuccess: isGetWebsiteSuccess,
    isError: isGetWebsiteError,
  } = useFetchWebsiteDataQuery();
  const { setShow, setMessage } = useSnackBar();
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [currentPage, setCurrentPage] = React.useState("");

  const onSubmit = React.useCallback(
    (values) => {
      const lng = selectedLang;
      const data = {
        seo: {
          [currentPage]: {
            title: {
              [lng]: values.title,
            },
            description: {
              [lng]: values.description,
            },
            keywords: {
              [lng]: values.keywords,
            },
          },
        },
      };
      editWebsite(data)
        .unwrap()
        .then(() => {
          setMessage({
            text: "website data is updated successfully",
            type: "success",
          });
          setShow(true);
          // wait(1000).then(() => navigate(POST_LIST_PATH));
        })
        .catch(() => {
          setMessage({
            text: "failed to update website data, please try again",
            type: "error",
          });
          setShow(true);
        });
    },
    [editWebsite, setShow, setMessage, selectedLang, currentPage]
  );
  const formProps = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  });
  const handleLanguageSelect = (e) => {
    setSelectedLang(e.target.value);
  };

  /* set default values to db values */
  React.useEffect(() => {
    if (isGetWebsiteSuccess && currentPage) {
      const lng = selectedLang;
      const { seo } = websiteData;
      formProps.setValues({
        title: seo?.[currentPage]?.title?.[lng],
        description: seo?.[currentPage]?.description?.[lng],
        keywords: seo?.[currentPage]?.keywords?.[lng],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetWebsiteSuccess, selectedLang, currentPage]);

  if (isGetWebsiteLoading) return <SimpleLoader />;
  if (isGetWebsiteError) return <SimpleErrorMessage />;
  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit}
      dir={selectedLang === "en" ? "ltr" : "rtl"}
    >
      <Select
        value={selectedLang}
        onChange={handleLanguageSelect}
        defaultValue="en"
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
      {/* START OF FIELDS */}
      <FormSection
        title={
          !currentPage
            ? "Please select a page to edit seo"
            : `You are now editing "${camelToSpaces(currentPage)}" page SEO`
        }
      >
        <FormControl>
          <InputLabel id="selected-page">Select A Page</InputLabel>
          <Select
            labelId="selected-page"
            id="selected-page"
            value={currentPage}
            label="Select A Page"
            onChange={(e) => setCurrentPage(e.target.value)}
            sx={{
              minWidth: "150px",
            }}
          >
            {typeof websiteData.seo === "object" &&
              Object.keys(websiteData.seo).map((page) => (
                <MenuItem value={String(page)}>{camelToSpaces(page)}</MenuItem>
              ))}
          </Select>
        </FormControl>
        {currentPage && (
          <>
            <CustomTextInput
              {...formProps}
              name="title"
              label="meta title tag"
            />
            <CustomTextInput
              {...formProps}
              name="description"
              label="meta description tag"
            />
            <CustomTextInput
              {...formProps}
              name="keywords"
              label="meta keywords tag"
            />
          </>
        )}
      </FormSection>
      {/* END OF FIELDS */}

      <Button
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "20px",
        }}
        type="submit"
        variant="contained"
        disabled={isEditingWebsite || !currentPage}
      >
        {isEditingWebsite ? "Loading..." : "Save Changes"}
      </Button>
    </form>
  );
};

export default HomeEditForm;

const initialValues = {
  title: "",
  description: "",
  keywords: "",
};

const validationSchema = Yup.object().shape({});
