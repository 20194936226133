import { Box, Typography } from "@mui/material";
import React from "react";

const FormSection = ({ title, children }) => {
  return (
    <Box sx={{ my: "25px", p: "5px" }}>
      <Typography sx={{ mb: "15px" }} variant="h3">
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default FormSection;
