import React from "react";

import AddIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { useSnackBar } from "context/SnackbarContext";
import { useFormik } from "formik";
import {
  useEditWebsiteDataMutation,
  useFetchWebsiteDataQuery,
} from "store/apis/websiteApi";
import { flex__center } from "themes/sx";
import AppHtmlEditor from "ui-component/AppHtmlEditor";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import ImageUploader from "ui-component/ImageUploader";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoader from "ui-component/SimpleLoading";
import * as Yup from "yup";
import FAQEditableAccordion from "./FAQEditableAccordion";
import AppModal from "ui-component/AppModal";
import FAQForm from "./FAQForm";
const WebsiteAboutAndFaqsEditForm = () => {
  const [editWebsite, { isLoading: isEditingWebsite }] =
    useEditWebsiteDataMutation();
  const {
    data: websiteData,
    isLoading: isGetWebsiteLoading,
    isSuccess: isGetWebsiteSuccess,
    isError: isGetWebsiteError,
  } = useFetchWebsiteDataQuery();
  const { setShow, setMessage } = useSnackBar();
  // states
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [openEditFaqModal, setOpenFaqModal] = React.useState();

  const onSubmit = React.useCallback(
    (values) => {
      const lng = selectedLang;
      const data = {
        aboutUs: {
          image: values.aboutUsImage,
          content: {
            [lng]: values.aboutUsContent,
          },
        },
        FAQs: {
          image: values.faqsImage,
          title: {
            [lng]: values.faqsTitle,
          },
          faqList: values.faqList,
        },
      };
      editWebsite(data)
        .unwrap()
        .then(() => {
          setMessage({
            text: "website data is updated successfully",
            type: "success",
          });
          setShow(true);
          // wait(1000).then(() => navigate(POST_LIST_PATH));
        })
        .catch(() => {
          setMessage({
            text: "failed to update website data, please try again",
            type: "error",
          });
          setShow(true);
        });
    },
    [editWebsite, setShow, setMessage, selectedLang]
  );
  const formProps = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  });
  const handleLanguageSelect = (e) => {
    setSelectedLang(e.target.value);
  };

  /* set default values to db values */
  React.useEffect(() => {
    if (isGetWebsiteSuccess) {
      const lng = selectedLang;
      formProps.setValues({
        aboutUsImage: websiteData?.aboutUs?.image,
        aboutUsContent: websiteData?.aboutUs?.content?.[lng],
        faqsImage: websiteData?.FAQs?.image,
        faqsTitle: websiteData?.FAQs?.title?.[lng],
        faqList: websiteData?.FAQs?.faqList,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetWebsiteSuccess, selectedLang]);

  if (isGetWebsiteLoading) return <SimpleLoader />;
  if (isGetWebsiteError) return <SimpleErrorMessage />;
  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit}
      dir={selectedLang === "en" ? "ltr" : "rtl"}
    >
      <Select
        value={selectedLang}
        onChange={handleLanguageSelect}
        defaultValue="en"
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
      {/* START OF FIELDS */}
      <FormSection title="About us">
        <Box sx={{ ...flex__center, mb: "20px" }}>
          <ImageUploader
            title="About us image"
            defaultThumbnail={formProps.values.aboutUsImage}
            onAfterUpload={(imageUrl) => {
              formProps.setFieldValue("aboutUsImage", imageUrl);
            }}
          />
        </Box>
      </FormSection>
      <FormSection title="About us content">
        <AppHtmlEditor
          defaultValue={formProps.values.aboutUsContent}
          onRichTextEditorChange={(content) => {
            formProps.setFieldValue("aboutUsContent", content);
          }}
          label="About Us content"
          displayMode="EDIT"
          placeholder="Write about us content"
        />
      </FormSection>

      {/* FAQs */}
      <FormSection title="FAQs">
        <Box sx={{ ...flex__center, mb: "20px" }}>
          <ImageUploader
            title="faqs image"
            defaultThumbnail={formProps.values.faqsImage}
            onAfterUpload={(imageUrl) => {
              formProps.setFieldValue("faqsImage", imageUrl);
            }}
          />
        </Box>
        <CustomTextInput {...formProps} name="faqsTitle" label="FAQS title" />
      </FormSection>
      <FormSection title="FAQs List">
        <Box my={3}>
          <Button
            onClick={() => setOpenFaqModal(true)}
            my={"10px"}
            color="secondary"
            variant="contained"
          >
            <Typography marginX={2}>Add New FAQ</Typography>
            <AddIcon />
          </Button>
        </Box>

        <AppModal open={openEditFaqModal} setOpen={setOpenFaqModal}>
          <FAQForm
            onAfterSave={(values) => {
              setOpenFaqModal(false);
              const newFaq = {
                title: {
                  [selectedLang]: values.title,
                },
                description: {
                  [selectedLang]: values.description,
                },
              };
              const faqs = [...formProps.values.faqList, newFaq];

              formProps.setFieldValue("faqList", faqs);
            }}
          />
        </AppModal>
        {formProps.values.faqList.map((faq, idx) => (
          <FAQEditableAccordion
            key={faq._id}
            title={faq.title?.[selectedLang]}
            description={faq.description?.[selectedLang]}
            onAfterDelete={() => {
              const faqs = [...formProps.values.faqList];
              faqs.splice(idx, 1);
              formProps.setFieldValue("faqList", faqs);
            }}
            onAfterSave={(values) => {
              const faqs = [...formProps.values.faqList];
              console.log("values", values);
              faqs[idx] = {
                title: {
                  ...faq.title,
                  [selectedLang]: values.title,
                },
                description: {
                  ...faq.description,
                  [selectedLang]: values.description,
                },
              };

              formProps.setFieldValue("faqList", faqs);
            }}
          />
        ))}
      </FormSection>

      <Button
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "20px",
        }}
        type="submit"
        variant="contained"
        disabled={isEditingWebsite}
      >
        {isEditingWebsite ? "Loading..." : "Save Changes"}
      </Button>
    </form>
  );
};

export default WebsiteAboutAndFaqsEditForm;

const initialValues = {
  aboutUsImage: "",
  aboutUsContent: "",
  faqsImage: "",
  faqsTitle: "",
  faqList: [],
};

const validationSchema = Yup.object().shape({});
