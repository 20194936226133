import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import React from "react";
const SimpleErrorMessage = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Alert severity="error" sx={{ fontSize: 18 }}>
        Failed to get data, please refresh the page and try again!
      </Alert>
    </Box>
  );
};

export default SimpleErrorMessage;
