import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axiosBaseQuery";

export const pagesApi = createApi({
  reducerPath: "pages",
  tagTypes: ["Pages"],
  baseQuery: axiosBaseQuery({
    baseUrl: "/admin/pages",
  }),
  endpoints: (build) => ({
    listPages: build.query({
      query: () => ({ url: "/all" }),
      providesTags: [{ type: "Pages", id: "LIST" }],
    }),
    showPage: build.query({
      query: (id) => ({ url: `/by-id/${id}` }),
      providesTags: [{ type: "Pages", id: "ONE" }],
    }),
    createPage: build.mutation({
      query: (data) => ({
        url: "/create",
        method: "post",
        data,
      }),
      invalidatesTags: [{ type: "Pages", id: "LIST" }],
    }),
    editPage: build.mutation({
      query: ({ _id, ...data }) => ({
        url: `/update/${_id}`,
        method: "PUT",
        data: { ...data },
      }),
      invalidatesTags: [
        { type: "Pages", id: "LIST" },
        { type: "Pages", id: "ONE" },
      ],
    }),

    deletePage: build.mutation({
      query: (id) => ({ url: `/remove/${id}`, method: "delete" }),
      invalidatesTags: [{ type: "Pages", id: "LIST" }],
    }),
  }),
});

export const {
  useCreatePageMutation,
  useDeletePageMutation,
  useEditPageMutation,
  useShowPageQuery,
  useListPagesQuery,
} = pagesApi;
