import React from "react";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import AppContextsProvider from "context";

// ===========================|| APP ||=========================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  // React.useEffect(() => {
  //   console.log(themes(customization));
  // }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <AppContextsProvider>
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </AppContextsProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
