import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import customization from "./slices/customizationSlice";
import { blogApi } from "./apis/blogApi";
import { tagsApi } from "./apis/tagsApi";
import { categoriesApi } from "./apis/categoriesApi";
import { contactApi } from "./apis/contactApi";
import { websiteApi } from "./apis/websiteApi";
import { userApi } from "./apis/userApi";
import { pagesApi } from "./apis/pagesApi";
// ===========================|| REDUX - MAIN STORE ||=========================== //

const store = configureStore({
  reducer: {
    customization,
    [blogApi.reducerPath]: blogApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [websiteApi.reducerPath]: websiteApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [pagesApi.reducerPath]: pagesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      blogApi.middleware,
      tagsApi.middleware,
      categoriesApi.middleware,
      contactApi.middleware,
      websiteApi.middleware,
      userApi.middleware,
      pagesApi.middleware,
    ]),
});
// const store = createStore(reducer);
const persister = "Demo";
setupListeners(store.dispatch);
export { store, persister };
