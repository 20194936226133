import { Typography } from "@mui/material";
import React from "react";
import PageForm from "./PageForm";

const EditPage = () => {
  return (
    <div>
      <Typography variant="h1">Edit Page</Typography>
      <PageForm isEdit />
    </div>
  );
};

export default EditPage;
