import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useSnackBar } from "context/SnackbarContext";
import * as dayjs from "dayjs";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { EDIT_PAGES_PATH } from "routes/paths";
import { useDeletePageMutation } from "store/apis/pagesApi";
import ConfirmActionDialog from "ui-component/ConfirmActionDialog";

export default function PageCard({ page = {} }) {
  const { seo, slug } = page || {};
  const navigate = useNavigate();
  const { setMessage, setShow } = useSnackBar();
  const [deletePage, { isLoading: isDeletingPage }] = useDeletePageMutation();
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const _handleDeletion = React.useCallback(() => {
    deletePage(page._id)
      .unwrap()
      .then(() => {
        setMessage({
          text: "Page has been deleted successfully",
          type: "success",
        });
      })
      .catch(() => {
        setMessage({ text: "Could not delete page!" });
      })
      .finally(() => {
        setShow(true);
      });
  }, [setShow, setMessage, deletePage, page._id]);

  return (
    <>
      <ConfirmActionDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={_handleDeletion}
      />
      <Card sx={{ maxWidth: "auto", borderRadius: "15px" }}>
        <CardHeader
          sx={{
            textTransform: "capitalize",
          }}
          action={
            <Box>
              {isDeletingPage ? (
                <CircularProgress size="25px" color="error" />
              ) : (
                <>
                  <Tooltip title="Edit Page">
                    <IconButton
                      onClick={() => navigate(`${EDIT_PAGES_PATH}/${page._id}`)}
                      aria-label="edit"
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="delete this Page">
                    <IconButton
                      onClick={() => setOpenConfirmDialog(true)}
                      aria-label="delete"
                      size="large"
                      color="error"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
          }
          title={seo?.title?.en || "no title provided"}
          subheader={
            <Box>
              <Typography>{slug}</Typography>
              <Typography>
                {dayjs(page?.createdAt).format("MMMM D, YYYY")}
              </Typography>
            </Box>
          }
        />
        {/* <CardMedia component="img" height="194" image={thumbnail} alt="" /> */}
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {seo?.description?.en || "no description provided"}
          </Typography>

          <Box>
            <Typography fontWeight="bold" mt="20px" mb="7px">
              keywords
            </Typography>
            {!seo?.keywords?.en && (
              <Typography color="gray" fontSize="12px">
                No keywords selected
              </Typography>
            )}

            <Grid container spacing="7">
              {typeof seo?.keywords?.en === "string" &&
                seo?.keywords?.en &&
                seo.keywords.en.split(",").map((keyword) => (
                  <Grid key={keyword} item>
                    <Chip key={keyword} label={keyword} />
                  </Grid>
                ))}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
