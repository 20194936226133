import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axiosBaseQuery";

export const contactApi = createApi({
  reducerPath: "contact",
  tagTypes: ["PERSON", "COMPANY", "MESSAGE"],
  baseQuery: axiosBaseQuery({
    baseUrl: "/admin",
  }),
  endpoints: (build) => ({
    fetchContactPersons: build.query({
      query: ({ page, perPage }) => ({
        url: `/contact/persons?page=${page}&perPage=${perPage}`,
      }),
      providesTags: [{ type: "PERSON", id: "LIST" }],
    }),

    markPersonAsRead: build.mutation({
      query: ({ id }) => ({
        method: "PUT",
        url: `/contact/persons/mark-as-read/${id}`,
      }),
      invalidatesTags: [{ type: "PERSON", id: "LIST" }],
    }),
    deletePerson: build.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `/contact/persons/${id}`,
      }),
      invalidatesTags: [{ type: "PERSON", id: "LIST" }],
    }),

    fetchContactCompanies: build.query({
      query: ({ page, perPage }) => ({
        url: `/contact/companies?page=${page}&perPage=${perPage}`,
      }),
      providesTags: [{ type: "COMPANY", id: "LIST" }],
    }),

    markCompanyAsRead: build.mutation({
      query: ({ id }) => ({
        method: "PUT",
        url: `/contact/companies/mark-as-read/${id}`,
      }),
      invalidatesTags: [{ type: "COMPANY", id: "LIST" }],
    }),
    deleteCompany: build.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `/contact/companies/${id}`,
      }),
      invalidatesTags: [{ type: "COMPANY", id: "LIST" }],
    }),

    fetchContactMessages: build.query({
      query: ({ page, perPage }) => ({
        url: `/contact/messages?page=${page}&perPage=${perPage}`,
      }),
      providesTags: [{ type: "MESSAGE", id: "LIST" }],
    }),
    markMessageAsRead: build.mutation({
      query: ({ id }) => ({
        method: "PUT",
        url: `/contact/messages/mark-as-read/${id}`,
      }),
      invalidatesTags: [{ type: "MESSAGE", id: "LIST" }],
    }),
    deleteMessage: build.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `/contact/messages/${id}`,
      }),
      invalidatesTags: [{ type: "MESSAGE", id: "LIST" }],
    }),
  }),
});

export const {
  useFetchContactPersonsQuery,
  useFetchContactCompaniesQuery,
  useFetchContactMessagesQuery,
  useDeleteCompanyMutation,
  useDeleteMessageMutation,
  useDeletePersonMutation,
  useMarkPersonAsReadMutation,
  useMarkCompanyAsReadMutation,
  useMarkMessageAsReadMutation,
} = contactApi;
