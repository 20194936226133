// admins
export const CONTROL_ADMINS_PATH = "/admin/control-admins";

// blog posts
export const POST_FORM_PATH = "/blog/post/form";
export const POST_LIST_PATH = "/blog/post/list";

// contact
export const COMPANIES_PATH = "/contact/companies";
export const PERSONS_PATH = "/contact/persons";
export const MESSAGES_PATH = "/contact/messages";

// website
export const WEBSITE_HOME_PATH = "/website/home";
export const WEBSITE_SERVICE_AND_FEATURES_PATH =
  "/website/services-and-features";

export const WEBSITE_GLOBALS_PATH = "/website/globals";
export const WEBSITE_ABOUT_AND_FAQS_PATH = "/website/about-faqs";
export const WEBSITE_SEO_PATH = "/website/seo";

// pages
export const LIST_PAGES_PATH = "/pages/list";
export const EDIT_PAGES_PATH = "/pages/edit";
export const CREATE_PAGES_PATH = "/pages/create";
