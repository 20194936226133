import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackBar } from "context/SnackbarContext";
import { useFormik } from "formik";
import React from "react";
import {
  useEditWebsiteDataMutation,
  useFetchWebsiteDataQuery,
} from "store/apis/websiteApi";
import AppChip from "ui-component/AppChip";
import AppModal from "ui-component/AppModal";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoader from "ui-component/SimpleLoading";
import * as Yup from "yup";
import NavbarLinkForm from "./NavbarLinkForm";
import AddIcon from "@mui/icons-material/AddCircle";

import SocialLinkForm from "./SocialLinkForm";
import Typography from "@mui/material/Typography";

const WebsiteGlobalsEditForm = () => {
  const [editWebsite, { isLoading: isEditingWebsite }] =
    useEditWebsiteDataMutation();
  const {
    data: websiteData,
    isLoading: isGetWebsiteLoading,
    isSuccess: isGetWebsiteSuccess,
    isError: isGetWebsiteError,
  } = useFetchWebsiteDataQuery();
  const { setShow, setMessage } = useSnackBar();
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [openAddNavbarLinkModal, setOpenAddNavbarLinkModal] =
    React.useState(false);
  const [openSocialLinkModal, setOpenSocialLinkModal] = React.useState(false);
  const handleLanguageSelect = (e) => {
    setSelectedLang(e.target.value);
  };

  const onSubmit = React.useCallback(
    (values) => {
      const lng = selectedLang;
      const data = {
        navbar: {
          navbarLinks: values.navbarLinks,
          showHireButton: values.showHireButton,
          showSearch: values.showSearch,
          showSidebar: values.showSidebar,
        },
        socialLinks: values.socialLinks,
        contactEmail: values.contactEmail,
        ksaOfficeInfo: {
          address: { [lng]: values.ksaAddress },
          phone: values.ksaPhone,
        },

        egyptOfficeInfo: {
          address: { [lng]: values.egyptAddress },
          phone: values.egyptPhone,
        },
        mapEmbedUrl: values.mapEmbedUrl,
        showLetsMakeImg: values.showLetsMakeImg,
        letsMakeTitle: {
          ...websiteData?.letsMakeTitle,
          [lng]: values.letsMakeTitle,
        },
        letsMakeButton: {
          text: {
            ...websiteData?.letsMakeButton?.text,
            [lng]: values.letsMakeButtonText,
          },
          href: values.letsMakeButtonLink,
        },
      };
      editWebsite(data)
        .unwrap()
        .then(() => {
          setMessage({
            text: "website data is updated successfully",
            type: "success",
          });
          setShow(true);
          // wait(1000).then(() => navigate(POST_LIST_PATH));
        })
        .catch(() => {
          setMessage({
            text: "failed to update website data, please try again",
            type: "error",
          });
          setShow(true);
        });
    },
    [editWebsite, setShow, setMessage, selectedLang, websiteData]
  );
  const formProps = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  });

  /* set default values to db values */
  React.useEffect(() => {
    if (isGetWebsiteSuccess) {
      const lng = selectedLang;
      formProps.setValues({
        navbarLinks: websiteData?.navbar?.navbarLinks,
        showHireButton: websiteData?.navbar?.showHireButton,
        showSearch: websiteData?.navbar?.showSearch,
        showSidebar: websiteData?.navbar?.showSidebar,
        socialLinks: websiteData?.socialLinks,
        ksaAddress: websiteData?.ksaOfficeInfo?.address?.[lng],
        ksaPhone: websiteData?.ksaOfficeInfo?.phone,
        egyptAddress: websiteData?.egyptOfficeInfo?.address?.[lng],
        egyptPhone: websiteData?.egyptOfficeInfo?.phone,
        mapEmbedUrl: websiteData?.mapEmbedUrl,
        showLetsMakeImg: websiteData?.showLetsMakeImg,
        letsMakeTitle: websiteData?.letsMakeTitle?.[lng],
        letsMakeButtonText: websiteData?.letsMakeButton?.text?.[lng],
        letsMakeButtonLink: websiteData?.letsMakeButton?.href,
        contactEmail: websiteData?.contactEmail,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetWebsiteSuccess, selectedLang]);

  if (isGetWebsiteLoading) return <SimpleLoader />;
  if (isGetWebsiteError) return <SimpleErrorMessage />;
  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit}
      dir={selectedLang === "en" ? "ltr" : "rtl"}
    >
      <Select
        value={selectedLang}
        onChange={handleLanguageSelect}
        defaultValue="en"
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
      {/* START OF FIELDS */}

      <FormSection title="Navbar Links">
        <Box my={3}>
          <Button
            onClick={() => setOpenAddNavbarLinkModal(true)}
            my={"10px"}
            color="secondary"
            variant="contained"
          >
            <Typography mr={2}>Add New Navbar Link</Typography>
            <AddIcon />
          </Button>
        </Box>
        <Stack direction="row" rowGap={2} spacing={1} flexWrap="wrap">
          <AppModal
            open={openAddNavbarLinkModal}
            setOpen={setOpenAddNavbarLinkModal}
          >
            <NavbarLinkForm
              closeModal={() => {
                setOpenAddNavbarLinkModal(false);
              }}
              onAfterSave={(values = {}) => {
                console.log(formProps.values.navbarLinks);
                const links = [
                  ...formProps.values.navbarLinks,
                  { ...values, name: { [selectedLang]: values.name } },
                ];

                formProps.setFieldValue("navbarLinks", [...links]);
              }}
            />
          </AppModal>
          {formProps.values.navbarLinks.map((link, idx) => (
            <>
              <AppChip
                key={link._id}
                label={link?.name?.[selectedLang]}
                handleDelete={() => {
                  const links = [...formProps.values.navbarLinks];
                  links.splice(idx, 1);
                  formProps.setFieldValue("navbarLinks", links);
                }}
                renderForm={(props) => (
                  <NavbarLinkForm
                    {...props}
                    defaultValues={{
                      ...link,
                      name: link?.name?.[selectedLang],
                    }}
                    onAfterSave={(values) => {
                      const links = [...formProps.values.navbarLinks];
                      links[idx] = {
                        ...values,
                        name: {
                          ...link.name,
                          [selectedLang]: values.name,
                        },
                      };

                      formProps.setFieldValue("navbarLinks", links);
                    }}
                  />
                )}
              />
            </>
          ))}
        </Stack>
      </FormSection>
      <FormSection title="Navbar settings">
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={formProps.values.showHireButton}
                onChange={formProps.handleChange}
                inputProps={{
                  name: "showHireButton",
                }}
              />
            }
            label="show hire button"
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={formProps.values.showSearch}
                onChange={formProps.handleChange}
                inputProps={{
                  name: "showSearch",
                }}
              />
            }
            label="show search"
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={formProps.values.showSidebar}
                onChange={formProps.handleChange}
                inputProps={{
                  name: "showSidebar",
                }}
              />
            }
            label="show side bar"
          />
        </Box>
      </FormSection>
      <FormSection title="social Links">
        <Box my={3}>
          <Button
            onClick={() => setOpenSocialLinkModal(true)}
            my={"10px"}
            color="secondary"
            variant="contained"
          >
            <Typography mr={2}>Add New Social Link</Typography>
            <AddIcon />
          </Button>
        </Box>
        <AppModal open={openSocialLinkModal} setOpen={setOpenSocialLinkModal}>
          <SocialLinkForm
            closeModal={() => {
              setOpenSocialLinkModal(false);
            }}
            onAfterSave={(values = {}) => {
              console.log(formProps.values.socialLinks);
              const links = [...formProps.values.socialLinks, { ...values }];

              formProps.setFieldValue("socialLinks", [...links]);
            }}
          />
        </AppModal>
        <Stack direction="row" spacing={1} rowGap={2} flexWrap="wrap">
          {formProps.values.socialLinks.map((link, idx) => (
            <Box>
              <AppChip
                key={link._id}
                label={link?.url}
                handleDelete={() => {
                  const links = [...formProps.values.socialLinks];
                  links.splice(idx, 1);
                  formProps.setFieldValue("socialLinks", links);
                }}
                renderForm={(props) => (
                  <SocialLinkForm
                    {...props}
                    defaultValues={{
                      logo: link?.logo,
                      url: link?.url,
                    }}
                    onAfterSave={(values) => {
                      const links = [...formProps.values.socialLinks];
                      links[idx] = {
                        logo: values.logo,
                        url: values.url,
                      };

                      formProps.setFieldValue("socialLinks", links);
                    }}
                  />
                )}
              />
            </Box>
          ))}
        </Stack>
      </FormSection>

      <FormSection title="KSA office information">
        <CustomTextInput {...formProps} name="ksaAddress" label="address" />
        <CustomTextInput {...formProps} name="ksaPhone" label="phone number" />
      </FormSection>
      <FormSection title="Egypt office information">
        <CustomTextInput {...formProps} name="egyptAddress" label="address" />
        <CustomTextInput
          {...formProps}
          name="egyptPhone"
          label="phone number"
        />
      </FormSection>

      <FormSection title="settings">
        <CustomTextInput
          {...formProps}
          name="mapEmbedUrl"
          label="map embed url"
        />
        <CustomTextInput
          {...formProps}
          name="contactEmail"
          label="Contact Email"
        />

        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={formProps.values.showLetsMakeImg}
                onChange={formProps.handleChange}
                inputProps={{
                  name: "showLetsMakeImg",
                }}
              />
            }
            label="show lets make image"
          />
        </Box>
        <CustomTextInput
          {...formProps}
          name="letsMakeTitle"
          label="(let's make section) title"
        />
        <CustomTextInput
          {...formProps}
          name="letsMakeButtonText"
          label="(let's make section) button text"
        />
        <CustomTextInput
          {...formProps}
          name="letsMakeButtonLink"
          label="(let's make section) button link"
        />
      </FormSection>

      {/* END OF FIELDS */}
      <Button
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "20px",
        }}
        type="submit"
        variant="contained"
        disabled={isEditingWebsite}
      >
        {isEditingWebsite ? "Loading..." : "Save Changes"}
      </Button>
    </form>
  );
};

export default WebsiteGlobalsEditForm;

const initialValues = {
  navbarLinks: [],
  showHireButton: true,
  showSearch: true,
  showSidebar: true,
  socialLinks: [],
  ksaAddress: "",
  ksaPhone: "",
  egyptAddress: "",
  egyptPhone: "",
  mapEmbedUrl: "",
  showLetsMakeImg: "",
  letsMakeTitle: "",
  letsMakeButtonText: "",
  letsMakeButtonLink: "",
  contactEmail: "",
};

const validationSchema = Yup.object().shape({});
