import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axiosBaseQuery";

export const blogApi = createApi({
  reducerPath: "blog",
  tagTypes: ["BlogPost"],
  baseQuery: axiosBaseQuery({
    baseUrl: "/admin",
  }),
  endpoints: (build) => ({
    fetchBlogPosts: build.query({
      query: () => ({ url: "/blog-post" }),
      providesTags: [{ type: "BlogPost", id: "LIST" }],
    }),
    getPostById: build.query({
      query: (id) => ({ url: `/blog-post/${id}` }),
      providesTags: [{ type: "BlogPost", id: "ONE" }],
    }),
    createBlogPost: build.mutation({
      query: ({ lang, ...data }) => ({
        url: "/blog-post",
        method: "post",
        data,
        headers: {
          "Accept-Language": lang,
        },
      }),
      invalidatesTags: [{ type: "BlogPost", id: "LIST" }],
    }),
    editPost: build.mutation({
      query: ({ _id, lang, ...data }) => ({
        url: `/blog-post/${_id}`,
        method: "PATCH",
        data: { ...data },
        headers: {
          "Accept-Language": lang,
        },
      }),
      invalidatesTags: [
        { type: "BlogPost", id: "LIST" },
        { type: "BlogPost", id: "ONE" },
      ],
    }),

    deletePost: build.mutation({
      query: (id) => ({ url: `/blog-post/${id}`, method: "delete" }),
      invalidatesTags: [{ type: "BlogPost", id: "LIST" }],
    }),
  }),
});

export const {
  useFetchBlogPostsQuery,
  useCreateBlogPostMutation,
  useGetPostByIdQuery,
  useEditPostMutation,
  useDeletePostMutation,
} = blogApi;
