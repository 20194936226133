// assets
import { IconNotebook, IconFileInfo } from "@tabler/icons";
import { LIST_PAGES_PATH, CREATE_PAGES_PATH } from "routes/paths";

// constant
const icons = {
  IconNotebook,
  IconFileInfo,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const dynamicPages = {
  id: "dynamic-pages",
  title: "Dynamic Pages",
  type: "group",
  children: [
    {
      id: "show-pages",
      title: "List Pages",
      type: "item",
      url: LIST_PAGES_PATH,
      icon: icons.IconNotebook,

      breadcrumbs: false,
    },
    {
      id: "create-page",
      title: "Create Page",
      type: "item",
      url: CREATE_PAGES_PATH,
      icon: icons.IconFileInfo,

      breadcrumbs: false,
    },
  ],
};

export default dynamicPages;
