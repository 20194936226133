import { Box } from "@mui/material";
// import { EditorState, convertToRaw, ContentState } from "draft-js";
// import htmlToDraft from "html-to-draftjs";
import parser from "html-react-parser";
import React from "react";
import SunEditor from "suneditor-react";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  image,
  lineHeight,
  link,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
} from "suneditor/src/plugins";

const AppHtmlEditor = ({
  defaultValue,
  displayMode = "EDIT",
  onRichTextEditorChange = () => {},
  placeholder = "Write down post content",
}) => {
  // const [editorState, setEditorState] = React.useState(
  //   EditorState.createEmpty()
  // );
  const [richTextEditor, setRichTextEditor] = React.useState(defaultValue);
  // const [hideToolbar, setHideToolbar] = React.useState(true);

  const handleChange = React.useCallback(
    (content) => {
      setRichTextEditor(content);
      onRichTextEditorChange(content);
    },
    [onRichTextEditorChange]
  );

  React.useEffect(() => {
    // handleChange(defaultValue);
    setRichTextEditor(defaultValue);
  }, [defaultValue, handleChange]);

  if (displayMode === "EDIT") {
    return (
      <Box>
        <SunEditor
          autoFocus={false}
          lang="en"
          setOptions={{
            showPathLabel: false,
            minHeight: "50vh",
            maxHeight: "50vh",
            placeholder: { placeholder },
            plugins: [
              align,
              font,
              fontColor,
              fontSize,
              formatBlock,
              hiliteColor,
              horizontalRule,
              lineHeight,
              list,
              paragraphStyle,
              table,
              template,
              textStyle,
              image,
              link,
            ],
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
              ],
              ["fontColor", "hiliteColor"],
              ["removeFormat"],
              "/", // Line break
              ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],
              ["table", "link", "image"],
            ],
            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
            font: [
              "Arial",
              "Calibri",
              "Comic Sans",
              "Courier",
              "Garamond",
              "Georgia",
              "Impact",
              "Lucida Console",
              "Palatino Linotype",
              "Segoe UI",
              "Tahoma",
              "Times New Roman",
              "Trebuchet MS",
            ],
          }}
          setContents={richTextEditor}
          defaultValue={richTextEditor}
          onChange={handleChange}
        />
      </Box>
    );
  } else {
    return (
      <div>
        {richTextEditor && (
          <div>
            <div className="sun-editor">
              <div className="sun-editor-editable">
                {parser(richTextEditor)}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default AppHtmlEditor;
