import { DeleteOutline } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_PER_PAGE,
  DEFAULT_ROWS_PER_PAGE_OPTIONS,
  DEFAULT_TABLE_CELL_MIN_WIDTH,
} from "constants.js";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmActionDialog from "ui-component/ConfirmActionDialog";

export default function PaginationTable({
  rows = [],
  columns = [],
  total = 0,
  exportDataLinkUrl = "",
  onRowClick = () => {},
  deleteMutation = [],
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultPage = searchParams.get("page");
  const defaultRowsPerPage = searchParams.get("perPage");
  const [deleteRow, deletionActions] = deleteMutation;
  const [isDeletionConfirmOpen, setIsDeletionConfirmOpen] =
    React.useState(false);

  const [deletionId, setDeletionId] = React.useState(null);
  const [page, setPage] = React.useState(() =>
    defaultPage ? +defaultPage : DEFAULT_PAGINATION_PAGE
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    defaultRowsPerPage ? +defaultRowsPerPage : DEFAULT_PAGINATION_PER_PAGE
  );
  const navigate = useNavigate();

  const handleSearchParams = ({ page, perPage }) => {
    const data = {
      page: +page,
      perPage,
    };

    const search = new URLSearchParams(data).toString();
    navigate({ search /* pathname: location.pathname */ });
  };
  const handleChangePage = (event, newPage) => {
    handleSearchParams({ page: +newPage, perPage: +rowsPerPage });
    setPage(+newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    handleSearchParams({ page: 0, perPage: +event.target.value });
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const _handleDeletion = (_id) => {
    setIsDeletionConfirmOpen(true);
    setDeletionId(_id);
  };
  return (
    <>
      {exportDataLinkUrl && (
        <div style={{ marginBottom: 20 }}>
          <a target="_blank" href={exportDataLinkUrl} rel="noopener noreferrer">
            Export To Excel
          </a>
        </div>
      )}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align || "center"}
                    style={{
                      minWidth: column.minWidth || DEFAULT_TABLE_CELL_MIN_WIDTH,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, idx) => {
                const { isRead, _id } = row;
                return (
                  <TableRow
                    key={idx}
                    sx={{
                      cursor: isRead ? "initial" : "pointer",
                    }}
                    role="checkbox"
                    tabIndex={-1}
                    hover={!isRead}
                    onClick={() => {
                      if (isRead) return;
                      onRowClick(_id);
                    }}
                  >
                    <TableCell
                      align="center"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!isRead && (
                        <Box
                          sx={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            background: "teal",
                          }}
                        />
                      )}
                      {deletionActions?.isLoading && deletionId === _id ? (
                        <CircularProgress size={"20px"} />
                      ) : (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            _handleDeletion(_id);
                          }}
                          sx={{ color: "#f32b2b" }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </TableCell>

                    {columns?.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align || "center"}
                          sx={{
                            color: "#333",
                            fontWeight: isRead ? "initial" : "bold",
                          }}
                        >
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : column.isUrl ? (
                            <a
                              style={{
                                color: idx % 2 === 0 ? "#FEE440" : "",
                              }}
                              href={value}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              {column.urlPlaceholder}
                            </a>
                          ) : (
                            value || "-"
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ConfirmActionDialog
          title="You are about to delete this row!"
          open={isDeletionConfirmOpen}
          setOpen={setIsDeletionConfirmOpen}
          onConfirm={() =>
            typeof deleteRow === "function" && deleteRow({ id: deletionId })
          }
          onCancel={() => setDeletionId(null)}
        />
      </Paper>
    </>
  );
}
