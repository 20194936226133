import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { USER_ROLES } from "constants.js";
import { useFormik } from "formik";
import React from "react";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import * as yup from "yup";
const UserForm = ({ defaultValues, onAfterSubmit = () => {}, isLoading }) => {
  const createValidationSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    role: yup.string().required(),
    password: yup.string().required(),
  });
  const editValidationSchema = yup.object().shape({
    name: yup.string().optional(),
    email: yup.string().optional(),
    role: yup.string().optional(),
    password: yup.string().optional(),
  });

  const onSubmit = (values) => {
    onAfterSubmit(values);
  };
  const formProps = useFormik({
    onSubmit,
    initialValues,
    validationSchema: defaultValues
      ? editValidationSchema
      : createValidationSchema,
  });

  React.useEffect(() => {
    if (defaultValues) {
      formProps.setValues({
        name: defaultValues.name,
        email: defaultValues.email,
        role: defaultValues.role,
        // password: defaultValues.password,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
  return (
    <form noValidate onSubmit={formProps.handleSubmit}>
      <FormSection title={defaultValues ? "update user" : "crete new user"}>
        <CustomTextInput {...formProps} name="name" label="user name" />
        <CustomTextInput {...formProps} name="email" label="user email" />
        <FormControl>
          <InputLabel id="selected-role">Select A Role</InputLabel>
          <Select
            labelId="selected-role"
            id="selected-role"
            value={formProps.values.role}
            label="Select A Role"
            name="role"
            onBlur={formProps.handleBlur}
            onChange={formProps.handleChange}
            sx={{
              minWidth: "150px",
            }}
          >
            <MenuItem value={USER_ROLES.SUPER}>Super Admin</MenuItem>
            <MenuItem value={USER_ROLES.EDITOR}>editor Admin</MenuItem>
            <MenuItem value={USER_ROLES.PUBLISHER}>publisher Admin</MenuItem>
            <MenuItem value={USER_ROLES.FORMS}>forms Admin</MenuItem>
          </Select>
        </FormControl>
        <CustomTextInput
          type="password"
          {...formProps}
          name="password"
          label="user password"
        />
      </FormSection>

      <Button disabled={isLoading} type="submit" variant="contained">
        {isLoading
          ? "Loading..."
          : defaultValues
          ? "Save Changes"
          : "Create User"}
      </Button>
    </form>
  );
};

export default UserForm;

const initialValues = {
  name: "",
  email: "",
  role: "",
  // password: "",
};
