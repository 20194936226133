import React from "react";

// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";
import { useAuth } from "context/AuthContext";

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
  const { userData } = useAuth();
  const navItems =
    typeof menuItem === "function" &&
    menuItem(userData.role)?.items?.map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return navItems;
};

export default MenuList;
