import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React from "react";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";

const NavbarLinkForm = ({
  closeModal,
  defaultValues,
  onAfterSave = () => {},
}) => {
  const onSubmit = (values) => {
    closeModal();
    onAfterSave(values);
  };
  const formProps = useFormik({
    initialValues,
    onSubmit,
  });

  React.useEffect(() => {
    if (defaultValues) {
      formProps.setValues({
        name: defaultValues?.name,
        route: defaultValues?.route,
        isUrl: defaultValues?.isUrl,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);
  return (
    <form noValidate onSubmit={formProps.handleSubmit}>
      <FormSection title="navbar link">
        <CustomTextInput {...formProps} name="name" label="link name" />
        <CustomTextInput {...formProps} name="route" label="redirects to" />
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={formProps.values.isUrl}
                onChange={formProps.handleChange}
                inputProps={{
                  name: "isUrl",
                }}
              />
            }
            label="is this link a url?"
          />
        </Box>
      </FormSection>
      <Button type="submit" variant="outlined">
        Save
      </Button>
    </form>
  );
};

export default NavbarLinkForm;

const initialValues = {
  name: "",
  route: "",
  isUrl: false,
};
