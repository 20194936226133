import { Chip } from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import AppModal from "./AppModal";
const AppChip = ({
  label,
  handleDelete = () => {},
  renderForm = () => null,
}) => {
  const [open, setOpen] = React.useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  return (
    <Box>
      <AppModal open={open} setOpen={setOpen}>
        {renderForm({ closeModal })}
      </AppModal>
      <Chip
        label={label}
        clickable
        onClick={openModal}
        onDelete={handleDelete}
        variant="filled"
      />
    </Box>
  );
};

export default AppChip;
