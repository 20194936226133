import { Navigate, useRoutes } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import OnlyGuestRoutes from "./OnlyGuestRoutes";
import { useAuth } from "context/AuthContext";

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
  const { isAuth, userData } = useAuth();
  return useRoutes([
    MainRoutes({ isLoggedIn: isAuth, userRole: userData?.role }),
    OnlyGuestRoutes(isAuth),
    {
      path: "*",

      element: isAuth ? <Navigate to="/" /> : <Navigate to="/login" />,
    },
  ]);
}
