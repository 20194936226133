export const COOKIE_ACCESS_TOKEN_KEY = "accessToken";
export const COOKIE_REFRESH_TOKEN_KEY = "refreshToken";
export const COOKIE_USER_KEY = "user";

export const JWT_REGEX = /(^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$)/;

// paginated table options
export const DEFAULT_PAGINATION_PAGE = 0;
export const DEFAULT_PAGINATION_PER_PAGE = 10;
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 25, 50, 100];
export const DEFAULT_TABLE_CELL_MIN_WIDTH = 150;

export const USER_ROLES = {
  SUPER: "SUPER",
  EDITOR: "EDITOR",
  PUBLISHER: "PUBLISHER",
  FORMS: "FORMS",
};
