import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axiosBaseQuery";

export const userApi = createApi({
  reducerPath: "users",
  tagTypes: ["Users"],
  baseQuery: axiosBaseQuery({
    baseUrl: "/admin/users",
  }),
  endpoints: (build) => ({
    listUsers: build.query({
      query: () => ({ url: "/get-all" }),
      providesTags: [{ type: "Users", id: "LIST" }],
    }),
    showUser: build.query({
      query: (id) => ({ url: `/users/${id}` }),
    }),
    createUser: build.mutation({
      query: (data) => ({
        url: "/create",
        method: "post",
        data,
      }),
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
    editUser: build.mutation({
      query: ({ _id, ...data }) => ({
        url: `/update/${_id}`,
        method: "PUT",
        data: { ...data },
      }),
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),

    deleteUser: build.mutation({
      query: (id) => ({ url: `/remove/${id}`, method: "delete" }),
      invalidatesTags: [{ type: "Users", id: "LIST" }],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useShowUserQuery,
  useListUsersQuery,
} = userApi;
