// assets
import {
  IconHome,
  IconClipboardList,
  IconSettings,
  IconVocabulary,
  IconWorldLongitude,
} from "@tabler/icons";
import {
  WEBSITE_HOME_PATH,
  WEBSITE_SERVICE_AND_FEATURES_PATH,
  WEBSITE_GLOBALS_PATH,
  WEBSITE_ABOUT_AND_FAQS_PATH,
  WEBSITE_SEO_PATH,
} from "routes/paths";

// constant
const icons = {
  IconHome,
  IconClipboardList,
  IconSettings,
  IconVocabulary,
  IconWorldLongitude,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const website = {
  id: "website",
  title: "Website Control",
  type: "group",
  children: [
    {
      id: "Home",
      title: "Home Page",
      type: "item",
      url: WEBSITE_HOME_PATH,
      icon: icons.IconHome,

      breadcrumbs: false,
    },
    {
      id: "services-and-features",
      title: "Services and features",
      type: "item",
      url: WEBSITE_SERVICE_AND_FEATURES_PATH,
      icon: icons.IconClipboardList,

      breadcrumbs: false,
    },
    {
      id: "globals",
      title: "settings & layout",
      type: "item",
      url: WEBSITE_GLOBALS_PATH,
      icon: icons.IconSettings,

      breadcrumbs: false,
    },
    {
      id: "about-and-faqs",
      title: "About & FAQs",
      type: "item",
      url: WEBSITE_ABOUT_AND_FAQS_PATH,
      icon: icons.IconVocabulary,

      breadcrumbs: false,
    },
    {
      id: "seo",
      title: "Static Pages SEO",
      type: "item",
      url: WEBSITE_SEO_PATH,
      icon: icons.IconWorldLongitude,

      breadcrumbs: false,
    },
  ],
};

export default website;
