import React, { lazy } from "react";
import Loadable from "ui-component/Loadable";

// project imports
import MinimalLayout from "layout/MinimalLayout";
import { Navigate } from "react-router-dom";
// login option 3 routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication/Login"))
);
// const AuthRegister = Loadable(
//   lazy(() => import("views/pages/authentication/authentication/Register"))
// );

// ===========================|| AUTHENTICATION ROUTING ||=========================== //

const OnlyGuestRoutes = (isLoggedIn) => ({
  path: "/",
  element: <MinimalLayout />,

  children: [
    {
      path: "/login",
      element: !isLoggedIn ? <AuthLogin /> : <Navigate to="/" />,
    },
    // {
    //   path: '/register',
    //   element: !isLoggedIn ? <AuthRegister /> : <Navigate to="/" />,
    // },
  ],
});

export default OnlyGuestRoutes;
