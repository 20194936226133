// assets
import { IconUsers, IconBuildingWarehouse, IconMail } from "@tabler/icons";
import { COMPANIES_PATH, PERSONS_PATH, MESSAGES_PATH } from "routes/paths";

// constant
const icons = {
  IconUsers,
  IconBuildingWarehouse,
  IconMail,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const contact = {
  id: "contact",
  title: "Contacts",
  type: "group",
  children: [
    {
      id: "persons",
      title: "Persons",
      type: "item",
      url: PERSONS_PATH,
      icon: icons.IconUsers,

      breadcrumbs: false,
    },
    {
      id: "companies",
      title: "Companies",
      type: "item",
      url: COMPANIES_PATH,
      icon: icons.IconBuildingWarehouse,

      breadcrumbs: false,
    },
    {
      id: "messages",
      title: "Messages",
      type: "item",
      url: MESSAGES_PATH,
      icon: icons.IconMail,

      breadcrumbs: false,
    },
  ],
};

export default contact;
