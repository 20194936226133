// assets
import { IconFileText, IconFilePlus } from "@tabler/icons";
import { POST_FORM_PATH, POST_LIST_PATH } from "routes/paths";

// constant
const icons = {
  IconFileText,
  IconFilePlus,
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const blog = {
  id: "blog",
  title: "Blog Posts",
  type: "group",
  children: [
    {
      id: "preview-posts",
      title: "Preview Posts",
      type: "item",
      url: POST_LIST_PATH,
      icon: icons.IconFileText,
      breadcrumbs: false,
    },
    {
      id: "create-post",
      title: "Create Post",
      type: "item",
      url: POST_FORM_PATH,
      icon: icons.IconFilePlus,

      breadcrumbs: false,
    },
  ],
};

export default blog;
