import * as React from "react";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/core/ModalUnstyled";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const style = {
  bgcolor: "#efefef",
  maxHeight: "90vh",
  overflowY: "auto",
  overflowX: "hidden",
  borderRadius: "5px",

  p: 2,
  px: 4,
  pb: 3,
};

export default function AppModal({
  children,
  open = false,
  setOpen = () => {},
}) {
  const handleClose = () => setOpen(false);

  return (
    <StyledModal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
    >
      <Box maxWidth={800} sx={style}>
        {children}
      </Box>
    </StyledModal>
  );
}
