import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineRounded from "@mui/icons-material/DeleteOutlineRounded";
import { Box } from "@mui/system";
import AppModal from "ui-component/AppModal";
import FAQForm from "./FAQForm";
import parser from "html-react-parser";

export default function FAQEditableAccordion({
  title,
  description,
  onAfterSave = () => {},
  onAfterDelete = () => {},
}) {
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleEditClick = (e) => {
    e.stopPropagation();
    setOpenEditModal(true);
  };
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onAfterDelete();
  };
  return (
    <>
      <AppModal open={openEditModal} setOpen={setOpenEditModal}>
        <FAQForm
          defaultValues={{
            title,
            description,
          }}
          onAfterSave={(values) => {
            setOpenEditModal(false);
            onAfterSave(values);
          }}
        />
      </AppModal>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: "20px",
          }}
        >
          <Box display="flex" alignItems="center">
            <IconButton
              color="error"
              onClick={handleDeleteClick}
              marginX="20px"
            >
              <DeleteOutlineRounded />
            </IconButton>
            <IconButton onClick={handleEditClick} marginX="20px">
              <EditIcon />
            </IconButton>

            <Typography marginX="20px" variant="h4">
              {title}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{parser(description)}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
