import { MenuItem, Select, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackBar } from "context/SnackbarContext";
import { useFormik } from "formik";
import React from "react";
import {
  useEditWebsiteDataMutation,
  useFetchWebsiteDataQuery,
} from "store/apis/websiteApi";
import { flex__center } from "themes/sx";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import ImageUploader from "ui-component/ImageUploader";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoader from "ui-component/SimpleLoading";
import * as Yup from "yup";
const HomeEditForm = () => {
  const [editWebsite, { isLoading: isEditingWebsite }] =
    useEditWebsiteDataMutation();
  const {
    data: websiteData,
    isLoading: isGetWebsiteLoading,
    isSuccess: isGetWebsiteSuccess,
    isError: isGetWebsiteError,
  } = useFetchWebsiteDataQuery();
  const { setShow, setMessage } = useSnackBar();
  const [selectedLang, setSelectedLang] = React.useState("en");

  const onSubmit = React.useCallback(
    (values) => {
      const lng = selectedLang;
      const data = {
        introSection: {
          mainPic: values.introMainPic,
          images: values.introImages,
          title: { [lng]: values.introTitle },
          subtitle: { [lng]: values.introSubtitle },
        },
        clientsLogos: values.clients,
        servicesSection: {
          title: { [lng]: values.servicesTitle },
          subtitle: { [lng]: values.servicesSubtitle },
        },
        whyTalentSupplierSection: {
          title: { [lng]: values.whyTalentTitle },
        },
      };
      editWebsite(data)
        .unwrap()
        .then(() => {
          setMessage({
            text: "website data is updated successfully",
            type: "success",
          });
          setShow(true);
          // wait(1000).then(() => navigate(POST_LIST_PATH));
        })
        .catch(() => {
          setMessage({
            text: "failed to update website data, please try again",
            type: "error",
          });
          setShow(true);
        });
    },
    [editWebsite, setShow, setMessage, selectedLang]
  );
  const formProps = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    validateOnChange: false,
  });
  const handleLanguageSelect = (e) => {
    setSelectedLang(e.target.value);
  };

  /* set default values to db values */
  React.useEffect(() => {
    if (isGetWebsiteSuccess) {
      const lng = selectedLang;
      formProps.setValues({
        introTitle: websiteData?.introSection?.title?.[lng],
        introSubtitle: websiteData?.introSection?.subtitle?.[lng],
        introMainPic: websiteData?.introSection?.mainPic,
        introImages: websiteData?.introSection?.images,
        clients: websiteData?.clientsLogos,
        servicesTitle: websiteData?.servicesSection?.title?.[lng],
        servicesSubtitle: websiteData?.servicesSection?.subtitle?.[lng],
        whyTalentTitle: websiteData?.whyTalentSupplierSection?.title?.[lng],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetWebsiteSuccess, selectedLang]);

  if (isGetWebsiteLoading) return <SimpleLoader />;
  if (isGetWebsiteError) return <SimpleErrorMessage />;
  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit}
      dir={selectedLang === "en" ? "ltr" : "rtl"}
    >
      <Select
        value={selectedLang}
        onChange={handleLanguageSelect}
        defaultValue="en"
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
      {/* START OF FIELDS */}
      <FormSection title="Intro Section">
        <Box sx={{ ...flex__center, mb: "20px" }}>
          <ImageUploader
            title="intro section main picture"
            defaultThumbnail={
              formProps.values.introMainPic ||
              websiteData?.introSection?.mainPic
            }
            onAfterUpload={(imageUrl) => {
              formProps.setFieldValue("introMainPic", imageUrl);
            }}
          />
        </Box>

        {/* intro images */}
        <Stack direction="row" overflow="auto" spacing={2}>
          {formProps.values.introImages instanceof Array &&
            formProps.values.introImages.map((img, idx) => (
              <Stack padding="10px">
                <ImageUploader
                  title={`intro picture number ${idx + 1}`}
                  defaultThumbnail={img}
                  onAfterUpload={(imageUrl) => {
                    let imgs = [...formProps.values.introImages];
                    imgs[idx] = imageUrl;
                    formProps.setFieldValue("introImages", imgs);
                  }}
                />
              </Stack>
            ))}
        </Stack>
        <CustomTextInput {...formProps} name="introTitle" label="intro title" />
        <CustomTextInput
          {...formProps}
          name="introSubtitle"
          label="intro subtitle"
        />
      </FormSection>

      <FormSection title="clients logos">
        {/* intro images */}
        <Stack direction="row" overflow="auto" spacing={2}>
          {formProps.values.clients instanceof Array &&
            formProps.values.clients.map((logo, idx) => (
              <Stack padding="10px">
                <ImageUploader
                  title={`client picture number ${idx + 1}`}
                  defaultThumbnail={logo}
                  onAfterDelete={() => {
                    let logos = [...formProps.values.clients];
                    logos.splice(idx, 1);
                    formProps.setFieldValue("clients", logos);
                  }}
                  onAfterUpload={(imageUrl) => {
                    let logos = [...formProps.values.clients];
                    logos[idx] = imageUrl;
                    formProps.setFieldValue("clients", logos);
                  }}
                />
              </Stack>
            ))}
          <Stack padding="10px">
            <ImageUploader
              title={`Add One More`}
              shouldPreviewImage={false}
              defaultThumbnail={null}
              onAfterUpload={(imageUrl) => {
                let logos = [...formProps.values.clients, imageUrl];
                formProps.setFieldValue("clients", logos);
              }}
            />
          </Stack>
        </Stack>
      </FormSection>

      <FormSection title="Services section">
        <CustomTextInput
          {...formProps}
          name="servicesTitle"
          label="services title"
        />
        <CustomTextInput
          {...formProps}
          name="servicesSubtitle"
          label="services subtitle"
        />
      </FormSection>
      {/* END OF FIELDS */}

      <FormSection title="Why Talent supplier section">
        <CustomTextInput
          {...formProps}
          name="whyTalentTitle"
          label="why talent title"
        />
      </FormSection>

      <Button
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "20px",
        }}
        type="submit"
        variant="contained"
        disabled={isEditingWebsite}
      >
        {isEditingWebsite ? "Loading..." : "Save Changes"}
      </Button>
    </form>
  );
};

export default HomeEditForm;

const initialValues = {
  introTitle: "",
  introSubtitle: "",
  introMainPic: "",
  introImages: [],
  clients: [],
  servicesTitle: "",
  servicesSubtitle: "",
  whyTalentTitle: "",
};

const validationSchema = Yup.object().shape({});
