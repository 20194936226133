import Button from "@mui/material/Button";
import { useFormik } from "formik";
import React from "react";
import AppHtmlEditor from "ui-component/AppHtmlEditor";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";

const FAQForm = ({ onAfterSave = () => {}, defaultValues }) => {
  const onSubmit = (values) => {
    onAfterSave(values);
  };
  const formProps = useFormik({
    initialValues,
    onSubmit,
  });

  React.useEffect(() => {
    if (defaultValues) {
      formProps.setValues({
        title: defaultValues.title,
        description: defaultValues.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <form noValidate onSubmit={formProps.handleSubmit}>
      <CustomTextInput {...formProps} name="title" label="title" />
      <FormSection title="faq description">
        <AppHtmlEditor
          defaultValue={formProps.values.description}
          onRichTextEditorChange={(content) => {
            formProps.setFieldValue("description", content);
          }}
          label="FAQs description"
          displayMode="EDIT"
          placeholder="Write your faq description"
        />
        <Button type="submit" variant="outlined">
          Save
        </Button>
      </FormSection>
    </form>
  );
};

export default FAQForm;

const initialValues = {
  title: "",
  description: "",
};
