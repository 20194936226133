const { AuthProvider } = require("./AuthContext");
const { SnackBarProvider } = require("./SnackbarContext");

const AppContextsProvider = ({ children }) => (
  <>
    <SnackBarProvider>
      <AuthProvider>{children}</AuthProvider>
    </SnackBarProvider>
  </>
);
export default AppContextsProvider;
