import { Snackbar } from "@mui/material";
import React from "react";
import config from "../config";
import { Alert } from "@mui/material";
const SnackBarContext = React.createContext({
  show: false,
  setShow() {},
  setMessage() {},
});

export const SnackBarProvider = React.memo(({ children }) => {
  const [show, setShow] = React.useState(false);
  const [message, setMessage] = React.useState({
    text: "",
    type: "error",
  });

  const handleClose = React.useCallback(() => {
    setShow(false);
  }, []);

  // React.useEffect(() => {
  //   console.table({ show, message })
  // }, [show, message])
  return (
    <SnackBarContext.Provider value={{ show, setShow, setMessage }}>
      <Snackbar
        open={show}
        autoHideDuration={config.snackBarAutoHideDuration}
        onClose={handleClose}
      >
        <Alert severity={message.type || "error"}>{message.text}</Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
});

export const useSnackBar = () => React.useContext(SnackBarContext);
