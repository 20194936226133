const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "/",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  snackBarAutoHideDuration: 6000,
  genericErrorMessage: "Something went wrong, Please try again",
  // apiUrl: "https://talent-supplier-api.herokuapp.com",// development
  // apiUrl: "http://localhost:3333", // local
  apiUrl: "https://apis.talentsupplier.co", //production,
  // apiUrl: process.env.API_URL,
};

export default config;
