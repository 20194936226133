import privateApi from "api/privateApi";
import extractErrMsg from "./extractErrMsg";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method = "get", data, headers = {} }) => {
    try {
      const result = await privateApi({
        url: baseUrl + url,
        method,
        data,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: { status: err.response?.status, data: extractErrMsg(err) },
      };
    }
  };

export default axiosBaseQuery;
