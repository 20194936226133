import React, { useEffect } from "react";
import {
  COOKIE_ACCESS_TOKEN_KEY,
  COOKIE_REFRESH_TOKEN_KEY,
  COOKIE_USER_KEY,
} from "../constants";
import Cookies from "js-cookie";
import extractErrMsg from "utils/extractErrMsg";
import { useSnackBar } from "./SnackbarContext";
import { clearCookies, getAccessToken } from "utils/getCookiesHelpers";
import Loader from "ui-component/Loader";
import publicApi from "../api/publicApi";
import privateApi from "api/privateApi";
import jsCookie from "js-cookie";

const AuthContext = React.createContext({
  isAuth: false,
  isValidating: true,
  userData: {},
  login() {},
  logout() {},
  setIsAuth() {},
});

// requests
const loginRequest = async ({ email, password }) =>
  await publicApi.post("auth/login", { email, password });

const logoutRequest = () => privateApi.get("auth/logout");

export const AuthProvider = React.memo(({ children }) => {
  const [isAuth, setIsAuth] = React.useState(false);
  const [isValidating, setIsValidating] = React.useState(true);
  const [userData, setUserData] = React.useState({});
  const { setMessage, setShow } = useSnackBar();

  const login = React.useCallback(
    (email, password, onError = () => {}) => {
      // loginMutation.mutate({ email, password })
      loginRequest({ email, password })
        .then(({ data }) => {
          Cookies.set(COOKIE_ACCESS_TOKEN_KEY, data.accessToken);
          Cookies.set(COOKIE_REFRESH_TOKEN_KEY, data.refreshToken);
          Cookies.set(COOKIE_USER_KEY, JSON.stringify(data.user));
          setUserData(() => data.user);
          setIsAuth(() => true);
        })
        .catch((error) => {
          setMessage((prev) => ({ ...prev, text: extractErrMsg(error) }));
          setShow(true);
          onError();
        })
        .finally(() => {
          setIsValidating(() => false);
        });
    },
    [setMessage, setShow]
  );

  const frontEndLogin = React.useCallback(() => {
    setIsAuth(getAccessToken && getAccessToken());
    const userData = JSON.parse(jsCookie.get("user") || "{}");
    setUserData(() => userData || {});
  }, []);
  const logout = React.useCallback(async () => {
    setIsValidating(() => true);
    await logoutRequest();
    setIsAuth(() => false);
    clearCookies();
    setIsValidating(() => false);
  }, []);

  useEffect(() => {
    frontEndLogin();
    // wait(2000).then(() => {
    // })
    setIsValidating(false);
  }, [frontEndLogin]);

  return (
    <AuthContext.Provider
      value={{ isAuth, isValidating, userData, login, logout, setIsAuth }}
    >
      {isValidating ? <Loader /> : children}
    </AuthContext.Provider>
  );
});

export const useAuth = () => React.useContext(AuthContext);
