import React from "react";
import Typography from "@mui/material/Typography";

const SimpleEmptyState = () => {
  return (
    <Typography textAlign="center" fontSize="25px" color="text.secondary">
      There's no current available data
    </Typography>
  );
};

export default SimpleEmptyState;
