import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useAuth } from "context/AuthContext";
import { useSnackBar } from "context/SnackbarContext";
import * as React from "react";
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
  useListUsersQuery,
} from "store/apis/userApi";
import AppModal from "ui-component/AppModal";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoader from "ui-component/SimpleLoading";
import UserForm from "./components/UserForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ControlAdmins() {
  const { data, isLoading, isError } = useListUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();
  const [editUser, { isLoading: isUpdating }] = useEditUserMutation();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const { setMessage, setShow } = useSnackBar();
  const { userData } = useAuth();

  const users = data;

  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenModal(true);
  };
  const handleRemove = (_id) => {
    deleteUser(_id)
      .unwrap()
      .then(() =>
        setMessage({ text: "User deleted successfully", type: "success" })
      )
      .catch(() => setMessage("Failed to delete user, please try again"))
      .finally(() => setShow(true));
  };

  const onCreateUserClick = () => {
    setOpenModal(true);
  };

  React.useEffect(() => {
    if (!openModal && !!selectedUser) setSelectedUser(null);
  }, [selectedUser, openModal]);

  if (isLoading) return <SimpleLoader />;
  if (isError) return <SimpleErrorMessage />;

  return (
    <Box>
      <AppModal open={openModal} setOpen={setOpenModal}>
        <UserForm
          isLoading={isCreating || isUpdating}
          defaultValues={selectedUser}
          onAfterSubmit={(values) => {
            if (selectedUser) {
              // edit
              editUser({ _id: selectedUser._id, ...values })
                .unwrap()
                .then(() => {
                  setMessage({
                    text: "User updated successfully",
                    type: "success",
                  });
                  setOpenModal(false);
                })
                .catch(() =>
                  setMessage({
                    text: "Failed to updated user, please try again",
                  })
                )
                .finally(() => setShow(true));
            } else {
              // create
              createUser({ ...values })
                .unwrap()
                .then(() => {
                  setMessage({
                    text: "User created successfully",
                    type: "success",
                  });
                  setOpenModal(false);
                })
                .catch(() =>
                  setMessage({
                    text: "Failed to create user, please try again",
                  })
                )
                .finally(() => setShow(true));
            }
          }}
        />
      </AppModal>
      <Typography variant="h2" mb="20px">
        System Users
      </Typography>
      <Box mb="20px">
        <Button
          onClick={onCreateUserClick}
          color="secondary"
          variant="contained"
        >
          <Typography mx="10px">Crete New User</Typography>
          <AddCircleIcon />
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">uuid</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Role</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledTableRow key={user._id}>
                <StyledTableCell align="center" component="th" scope="row">
                  {user._id}
                </StyledTableCell>
                <StyledTableCell align="center" component="th" scope="row">
                  {user.name}
                </StyledTableCell>
                <StyledTableCell align="center">{user.email}</StyledTableCell>
                <StyledTableCell align="center">{user.role}</StyledTableCell>
                <StyledTableCell align="center">
                  <Tooltip title="Edit user">
                    <IconButton
                      onClick={() => handleEdit(user)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {userData._id !== user._id && (
                    <Tooltip title="Delete user">
                      <IconButton
                        onClick={() => handleRemove(user._id)}
                        color="error"
                      >
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
