import { Box, Button, CircularProgress, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import * as React from "react";

export default function CustomDialog({
  onClose,
  open,
  dialogTitle,
  defaultValue = "",
  isLoading,
  onDialogAddClick = () => {},
}) {
  const [value, setValue] = React.useState(defaultValue);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{dialogTitle}</DialogTitle>

      <Box sx={{ minWidth: "350px", minHeight: "200px", padding: "20px" }}>
        <TextField
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Write something to add"
          sx={{ mb: "50px" }}
        />
        <Button variant="contained" onClick={() => onDialogAddClick(value)}>
          {isLoading ? (
            <CircularProgress sx={{ color: "#FFF" }} size="16px" />
          ) : (
            "Add"
          )}
        </Button>
      </Box>
    </Dialog>
  );
}

CustomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
