import { Button, FormHelperText, MenuItem, Select } from "@mui/material";
import { useSnackBar } from "context/SnackbarContext";
import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { LIST_PAGES_PATH } from "routes/paths";
import slugify from "slugify";
import {
  useCreatePageMutation,
  useEditPageMutation,
  useShowPageQuery,
} from "store/apis/pagesApi";
import AppHtmlEditor from "ui-component/AppHtmlEditor";
import CustomTextInput from "ui-component/forms/CustomTextInput";
import FormSection from "ui-component/forms/FormSection";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoader from "ui-component/SimpleLoading";
import wait from "utils/wait";
import * as Yup from "yup";
const PageForm = ({ isEdit }) => {
  const [selectedLang, setSelectedLang] = React.useState("en");
  const params = useParams();

  const navigate = useNavigate();
  const { setShow, setMessage } = useSnackBar();

  // api queries

  const [createPage, { isLoading: isCreating }] = useCreatePageMutation();

  const [editPage, { isLoading: isEditing }] = useEditPageMutation();

  const {
    data: pageData,
    isLoading: isGePostLoading,
    isSuccess: isGetPageSuccess,
    isError: isGetPostError,
  } = useShowPageQuery(params.id, { skip: !isEdit });

  const onSubmit = React.useCallback(
    (values) => {
      const payload = {
        slug: slugify(values.slug),
        seo: {
          title: {
            [selectedLang]: values.title,
          },
          description: {
            [selectedLang]: values.description,
          },
          keywords: {
            [selectedLang]: values.keywords,
          },
        },

        content: {
          [selectedLang]: values.content,
        },
      };

      if (isEdit) {
        editPage({
          _id: params.id,
          ...payload,
        })
          .unwrap()
          .then(() => {
            setMessage({
              text: "Page is updated successfully",
              type: "success",
            });
            setShow(true);
            wait(1000).then(() => navigate(LIST_PAGES_PATH));
          })
          .catch(() => {
            setMessage({
              text: "failed to update Page, please try again",
              type: "error",
            });
            setShow(true);
          });
      } else {
        createPage({
          ...payload,
        })
          .unwrap()
          .then(() => {
            setMessage({
              text: "Page is created successfully",
              type: "success",
            });
            setShow(true);
            wait(1000).then(() => navigate(LIST_PAGES_PATH));
          })
          .catch(() => {
            setMessage({
              text: "failed to create Page, please try again",
              type: "error",
            });
            setShow(true);
          });
      }
    },
    [
      createPage,
      editPage,
      params.id,
      setMessage,
      setShow,
      navigate,
      isEdit,
      selectedLang,
    ]
  );
  const formProps = useFormik({
    onSubmit,
    validationSchema,
    initialValues,
  });

  React.useEffect(() => {
    if (isEdit && isGetPageSuccess) {
      formProps.setValues({
        title: pageData?.seo?.title?.[selectedLang],
        description: pageData?.seo?.description?.[selectedLang],
        keywords: pageData?.seo?.keywords?.[selectedLang],
        slug: pageData?.slug,
        content: pageData?.content?.[selectedLang],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, isGetPageSuccess, selectedLang]);

  const handleLanguageSelect = (e) => {
    setSelectedLang(e.target.value);
  };

  if (isGePostLoading) return <SimpleLoader />;
  if (isGetPostError) return <SimpleErrorMessage />;
  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit}
      dir={selectedLang === "ar" ? "rtl" : "ltr"}
    >
      <Select
        value={selectedLang}
        onChange={handleLanguageSelect}
        defaultValue="en"
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
      <FormSection title="Page SEO">
        <CustomTextInput {...formProps} name="title" label="meta title tag" />
        <CustomTextInput
          {...formProps}
          name="description"
          label="meta description tag"
        />
        <CustomTextInput
          {...formProps}
          name="keywords"
          label="meta keywords tag"
        />
      </FormSection>
      <FormSection title="Page details">
        <CustomTextInput
          {...formProps}
          name="slug"
          label="page slug (is shown in url)"
        />
      </FormSection>

      <FormSection title="Post Content">
        <AppHtmlEditor
          defaultValue={formProps.values.content}
          onRichTextEditorChange={(content) => {
            formProps.setFieldValue("content", content);
          }}
          label="Page Content"
          displayMode="EDIT"
          placeholder="Write page content"
        />
        {!!formProps.errors.content && (
          <FormHelperText
            error={formProps.errors.content}
            id="standard-weight-helper-text-email-login"
          >
            {formProps.errors.content}
          </FormHelperText>
        )}
      </FormSection>

      <Button
        sx={{
          width: "200px",
          height: "50px",
          fontSize: "20px",
        }}
        type="submit"
        variant="contained"
        disabled={isCreating || isEditing}
      >
        {isCreating || isEditing ? (
          "Loading..."
        ) : (
          <>{isEdit ? "Save Changes" : "Create"}</>
        )}
      </Button>
    </form>
  );
};

export default PageForm;

const initialValues = {
  title: "",
  description: "",
  keywords: "",
  slug: "",
  content: "",
};

const validationSchema = Yup.object().shape({
  title: Yup.string().min(2).max(255).optional(),
  description: Yup.string().optional(),
  keywords: Yup.string().optional(),
  slug: Yup.string().min(2).max(50).required(),
  content: Yup.string().required(),
});
