import React from "react";
import PageForm from "./PageForm";

const CreatePage = () => {
  return (
    <div>
      <h1>Create Page</h1>
      <PageForm />
    </div>
  );
};

export default CreatePage;
