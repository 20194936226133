import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axiosBaseQuery";
import { websiteSeedData } from "website-seed-data";

export const websiteApi = createApi({
  reducerPath: "website",
  tagTypes: ["Website"],
  baseQuery: axiosBaseQuery({
    baseUrl: "/admin/website/",
  }),
  endpoints: (build) => ({
    fetchWebsiteData: build.query({
      query: () => ({ url: "content/get", method: "GET" }),
      providesTags: [{ type: "Website", id: "GET_DATA" }],
    }),
    resetWebsiteData: build.mutation({
      query: () => ({ url: "seed", method: "POST", data: websiteSeedData }),
      invalidatesTags: [{ type: "Website", id: "GET_DATA" }],
    }),
    editWebsiteData: build.mutation({
      query: ({ _id, lang, ...data }) => ({
        url: "content/update",
        method: "PUT",
        data: { ...data },
        headers: {
          "Accept-Language": lang,
        },
      }),
      invalidatesTags: [{ type: "Website", id: "GET_DATA" }],
    }),
  }),
});

export const {
  useEditWebsiteDataMutation,
  useFetchWebsiteDataQuery,
  useResetWebsiteDataMutation,
} = websiteApi;
