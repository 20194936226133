import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axiosBaseQuery";

export const categoriesApi = createApi({
  reducerPath: "categories",
  baseQuery: axiosBaseQuery({
    baseUrl: "/admin",
  }),
  tagTypes: ["Categories"],
  endpoints: (build) => ({
    fetchCategories: build.query({
      query: () => ({
        url: "/category",
      }),

      transformResponse: (data = []) => data?.map((item) => item.name),
      providesTags: [{ type: "Categories", id: "LIST" }],
    }),

    createCategories: build.mutation({
      query: (args) => ({
        url: "/category",
        method: "post",
        data: { name: args },
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
  }),
});

export const { useFetchCategoriesQuery, useCreateCategoriesMutation } =
  categoriesApi;
