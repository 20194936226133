import React, { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import { Navigate } from "react-router-dom";
import {
  POST_FORM_PATH,
  POST_LIST_PATH,
  PERSONS_PATH,
  COMPANIES_PATH,
  MESSAGES_PATH,
  WEBSITE_HOME_PATH,
  WEBSITE_SERVICE_AND_FEATURES_PATH,
  WEBSITE_GLOBALS_PATH,
  WEBSITE_ABOUT_AND_FAQS_PATH,
  WEBSITE_SEO_PATH,
  CONTROL_ADMINS_PATH,
  LIST_PAGES_PATH,
  EDIT_PAGES_PATH,
  CREATE_PAGES_PATH,
} from "./paths";
import ContactPersons from "views/contact/ContactPersons";
import ContactCompanies from "views/contact/ContactCompanies";
import ContactMessages from "views/contact/ContactMessages";
import HomeEditForm from "views/website/HomeEditForm";
import ServicesAndFeaturesEditForm from "views/website/ServicesAndFeaturesEditForm";
import WebsiteGlobalsEditForm from "views/website/WebsiteGlobalsEditForm";
import WebsiteAboutAndFaqsEditForm from "views/website/WebsiteAboutAndFaqsEditForm";
import WebsiteSeoEditFrom from "views/website/WebsiteSeoEditFrom";
import ControlAdmins from "views/admins/ControlAdmins";
import { USER_ROLES } from "constants.js";
import ListPages from "views/dynamic-pages/ListPages";
import EditPage from "views/dynamic-pages/EditPage";
import CreatePage from "views/dynamic-pages/CreatePage";

// dashboard routing
// const DashboardDefault = Loadable(
//   lazy(() => import("views/dashboard/Default"))
// );
const BlogPostForm = Loadable(lazy(() => import("views/blog/BlogPostForm")));
const PostsList = Loadable(lazy(() => import("views/blog/PostsList")));

// ===========================|| MAIN ROUTING ||=========================== //

const RedirectToLogin = () => <Navigate to="/login" />;

const mainRouteMapper = {
  [USER_ROLES.SUPER]: CONTROL_ADMINS_PATH,
  [USER_ROLES.EDITOR]: POST_LIST_PATH,
  [USER_ROLES.PUBLISHER]: POST_LIST_PATH,
  [USER_ROLES.FORMS]: PERSONS_PATH,
};
/* prevent certain roles to access routes */
const isNotForbidden = (forbiddenRolesList = [], userRole = "") =>
  !forbiddenRolesList.includes(userRole);

const MainRoutes = ({ isLoggedIn, userRole }) => ({
  path: "/",
  element: isLoggedIn ? <MainLayout /> : <RedirectToLogin />,
  children: [
    {
      path: "/",
      element: (
        <Navigate to={mainRouteMapper[userRole]} />
      ) /* <DashboardDefault /> */,
    },

    {
      path: POST_FORM_PATH,
      element: isNotForbidden([USER_ROLES.FORMS], userRole) ? (
        <BlogPostForm />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: POST_FORM_PATH + "/:id",
      element: isNotForbidden([USER_ROLES.FORMS], userRole) ? (
        <BlogPostForm isEdit={true} />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: POST_LIST_PATH,
      element: isNotForbidden([USER_ROLES.FORMS], userRole) ? (
        <PostsList />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: PERSONS_PATH,
      element: isNotForbidden([USER_ROLES.PUBLISHER], userRole) ? (
        <ContactPersons />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: COMPANIES_PATH,
      element: isNotForbidden([USER_ROLES.PUBLISHER], userRole) ? (
        <ContactCompanies />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: MESSAGES_PATH,
      element: isNotForbidden([USER_ROLES.PUBLISHER], userRole) ? (
        <ContactMessages />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: WEBSITE_HOME_PATH,
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <HomeEditForm />
      ) : (
        <Navigate to="/" />
      ),
    },

    {
      path: WEBSITE_SERVICE_AND_FEATURES_PATH,
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <ServicesAndFeaturesEditForm />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: WEBSITE_GLOBALS_PATH,
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <WebsiteGlobalsEditForm />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: WEBSITE_ABOUT_AND_FAQS_PATH,
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <WebsiteAboutAndFaqsEditForm />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: LIST_PAGES_PATH,
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <ListPages />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: EDIT_PAGES_PATH + "/:id",
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <EditPage />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: CREATE_PAGES_PATH,
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <CreatePage />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: WEBSITE_SEO_PATH,
      element: isNotForbidden(
        [USER_ROLES.PUBLISHER, USER_ROLES.EDITOR],
        userRole
      ) ? (
        <WebsiteSeoEditFrom />
      ) : (
        <Navigate to="/" />
      ),
    },

    {
      path: CONTROL_ADMINS_PATH,
      element:
        userRole === USER_ROLES.SUPER ? <ControlAdmins /> : <Navigate to="/" />,
    },
  ],
});

export default MainRoutes;
