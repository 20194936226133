import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "utils/axiosBaseQuery";

export const tagsApi = createApi({
  reducerPath: "tags",
  baseQuery: axiosBaseQuery({
    baseUrl: "/admin",
  }),
  tagTypes: ["Tags"],
  endpoints: (build) => ({
    fetchTags: build.query({
      query: () => ({
        url: "/tag",
      }),

      transformResponse: (data = []) => data?.map((item) => item.name),
      providesTags: [{ type: "Tags", id: "LIST" }],
    }),

    createTag: build.mutation({
      query: (args) => ({ url: "/tag", method: "post", data: { name: args } }),
      invalidatesTags: [{ type: "Tags", id: "LIST" }],
    }),
  }),
});

export const { useFetchTagsQuery, useCreateTagMutation } = tagsApi;
