import Typography from "@mui/material/Typography";
import React from "react";
import { useLocation } from "react-router";
import {
  useDeletePersonMutation,
  useFetchContactPersonsQuery,
  useMarkPersonAsReadMutation,
} from "store/apis/contactApi";
import SimpleEmptyState from "ui-component/SimpleEmptyState";
import SimpleErrorMessage from "ui-component/SimpleErrorMessage";
import SimpleLoading from "ui-component/SimpleLoading";
import PaginationTable from "ui-component/tables/PaginationTable";

import config from "config.js";
import {
  DEFAULT_PAGINATION_PAGE,
  DEFAULT_PAGINATION_PER_PAGE,
} from "constants.js";
const ContactPersons = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultPage = searchParams.get("page");
  const defaultRowsPerPage = searchParams.get("perPage");

  const { data, isError, isSuccess, isLoading } = useFetchContactPersonsQuery({
    page: defaultPage ? +defaultPage : DEFAULT_PAGINATION_PAGE,
    perPage: defaultRowsPerPage
      ? +defaultRowsPerPage
      : DEFAULT_PAGINATION_PER_PAGE,
  });

  const [markPersonAsRead] = useMarkPersonAsReadMutation();
  const deletePersonMutation = useDeletePersonMutation();
  const columns = [
    // {
    //   id: "density",
    //   label: "Density",
    //   minWidth: 170,
    //   align: "right",
    //   format: (value) => value.toFixed(2),
    // },
    { id: "firstName", label: "first name" },
    { id: "lastName", label: "last name" },
    { id: "email", label: "email" },
    { id: "country", label: "country" },
    { id: "phone", label: "phone" },
    { id: "primarySkill", label: "primary skill" },
    { id: "yearsOfSkillExperience", label: "skill experience" },
    { id: "yearsOfWorkExperience", label: "work experience" },
    { id: "englishProficiency", label: "english proficiency" },
    { id: "cv", label: "CV", urlPlaceholder: "Preview CV", isUrl: true },
    { id: "referredBy", label: "referred by" },
  ];
  return (
    <div>
      <Typography mb={5} variant="h1" color="gray">
        People Sent Those Contacts
      </Typography>
      {isLoading && <SimpleLoading />}
      {isError && <SimpleErrorMessage />}
      {isSuccess && data.data.length === 0 && <SimpleEmptyState />}
      {isSuccess && data?.data?.length > 0 && (
        <PaginationTable
          exportDataLinkUrl={`${config.apiUrl}/api/contact/persons/export`}
          rows={data?.data}
          columns={columns}
          total={data?.meta?.total}
          deleteMutation={deletePersonMutation}
          onRowClick={(id) => {
            markPersonAsRead({ id });
          }}
        />
      )}
    </div>
  );
};

export default ContactPersons;
